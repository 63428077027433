/* eslint-disable */
import React from "react"
import { Link, hashHistory } from "react-router"
import axios from "axios"
import { loadAnalytics, fbPixelCustomEvent } from "./withTracker"
import getResult from "./Api"
import DocumentMeta from "react-document-meta"
import cookie from "react-cookie"
import StripeCheckout from "react-stripe-checkout"
// import PaypalExpressBtn from "react-paypal-express-checkout"
import { includes } from "lodash"
import KoomipayCheckout from "./KoomipayCheckout"
import Loading from "./loading"

const apps = require("./apps")
import config from "./config"
import lang from "./lang"
const alertMsg = require("./AlertsMsg")
const APIURL = config.path.apiUrl
const APPID = config.ids.appId
const APIURLPRO = config.path.apiUrlPro
const APIURLv2 = config.path.apiUrlv2
const currency = config.ids.currency
var dateFormat = require("dateformat")
var now = new Date()
var currentDate = dateFormat(now, "d/m/yyyy")
var currentTime = dateFormat(now, "h:MM TT")
var qs = require("qs")
var HtmlToReactParser = require("html-to-react").Parser
var htmlToReactParserTo = new HtmlToReactParser()
var helper = require("./Helper.js")
var strp = require("strp")

const rewardsApplyList = ["3292DD26-CAEF-421D-81AF-72218513B3EE", "F77D467A-F552-4BB3-ABBF-C491DB6661A01"]
const rewardsApply = 1

//import Time from 'react-time';
var zonalEnable = 1
/* Load meta tags */
const meta = {
  title: lang.checkout_meta_title + lang.site_title_with_separator,
  description: lang.checkout_meta_title,
  meta: {
    name: {
      keywords: lang.checkout_meta_title,
    },
  },
}

class Chekout extends React.Component {
  constructor(props) {
    /*add class */
    helper.addBodyClass()
    super(props)
    /* Declare state */

    this.state = {
      offlineDesc: "",
      advocado_earn_points: "",
      pdpacontent: "",
      advocado_cashbackPercent: "",
      advocadopin: "",
      paymentGateway: "",
      zoneEnabled: 0,
      cartResult: 0,
      cartDetails: [],
      cartItems: [],
      status: "Loading",
      settings: [],
      paymentLoading: "/img/loading.gif",
      paymentSuccess: "/img/tick.png",
      orderLoading: "/img/loading.gif",
      orderSuccess: "/img/tick.png",
      paymentStatus: "CASH",
      defaultClass: "",
      windowHeightNormal: 0,
      windowHeight: 0,
      promoApplied: "No",
      promoAmount: 0,
      promocode: "",
      promoAppliedDelivery: "No",
      promotionCategory: "",
      promotionId: "",
      previousPromotionAmount: 0,
      originalSubTotal: 1000,
      originalGrandTotal: 1000,
      cartSubToatl: 0,
      dealersOverallTotal: 0,
      reedemptionApplied: "No",
      originalDeliveryAmnt: 0,
      isFreeDelivery: "No",
      promoQty: 0,
      stripeMinPay: "No",
      promoType: "",
      promoPoints: 0,
      customerPoints: 0,
      customerAdvocadoPoints: 0,
      zoneDetails: [],
      omisePayment: 0,
      appliedPromocode: typeof cookie.load("promotion_code") !== "undefined" ? cookie.load("promotion_code") : "",
      omisecodeerror: "",
      omiseyearerror: "",
      omisemontherror: "",
      omisecardrror: "",
      omisenameerror: "",
      holdername: "",
      cardNumber: "",
      expiration_month: "",
      expiration_year: "",
      security_code: "",
      cardImage: "",
      log_id: "",
      onlineservice_displaylabel: "Service Charge",
      online_servicecharge_type: "percentage",
      online_servicecharge_per: 0,
      online_servicecharge_amount: 0,
      subchr_amount: 0,
      subchr_type: "",
      subchr_date: "",
      subchr_frmtime: "",
      subchr_totime: "",
      payment_ref_id: "",
      customer_otp_limit: 0,
      omise_tokken_response: "",
      omise_tokken_card_id: "",
      omise_tokken_id: "",
      masterCardLogo: "",
      omisePayNowStatus: "",
      payNowCaptureId: "",
      paynowAttempts: 0,
      isPaymentProcessing: "",
      razerpayOrderId: "",
      statusInterval: null,
      isRazerpayScript: false,
      promo_with_delivery: "No",
      promo_type: "",
      newVoucher: "",
      omiseSearchAPICal: 1,
      zone_order_value_delivery_charge: 0,
      gift_option: false,
      recipient_name: "",
      recipient_contact_no: "",
      gift_message: "",
      applyRewardsOriginalGrandTotal: "",
      finalApplyRewardsOriginalGrandTotal: 999999,
      paypalPayment: false,
      proStockAndDatetimeAvl: "yes",

      validatedOrder: null,
      koomipayPayment: false,
    }

    /* surcharge details - Start */
    var availabilityIdTxt = cookie.load("defaultAvilablityId")
    var orderOutletId = cookie.load("orderOutletId")
    if (availabilityIdTxt === config.ids.deliveryId) {
      var cookieDate = cookie.load("DeliveryDate")
      var splitedDate = cookieDate.split("/")
      var selDate = this.pad(splitedDate[0])
      var selMonth = this.pad(splitedDate[1])
      var selYear = splitedDate[2]
      var orderDate = selYear + "-" + selMonth + "-" + selDate
      var orderTime = cookie.load("DeliveryTime")
    } else {
      var orderDate = ""
      var orderTime = ""
    }

    var online_servicecharge_type = this.state.online_servicecharge_type
    var onlineservice_displaylabel = this.state.onlineservice_displaylabel
    var online_servicecharge_per = 0,
      online_servicecharge_amount = 0,
      subchr_amount = 0
    var subchr_type = "",
      subchr_date = "",
      subchr_frmtime = "",
      subchr_totime = ""

    axios.get(APIURL + "outlets/outletsubcharge/?app_id=" + APPID + "&availabilityid=" + availabilityIdTxt + "&outletid=" + orderOutletId + "&orderdate=" + orderDate + "&ordertime=" + orderTime + "&").then((res) => {
      if (res.data.status === "ok") {
        online_servicecharge_type = res.data.onlineservice_type
        onlineservice_displaylabel = res.data.onlineservice_displaylbl
        if (res.data.onlineservice_type === "fixed") {
          online_servicecharge_per = 0
          online_servicecharge_amount = res.data.online_service_charge
        } else {
          online_servicecharge_per = res.data.online_service_charge
          online_servicecharge_amount = 0
        }

        if (res.data.subcharge_apply === "Yes" && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
          var subchr_data = res.data.result_set[0]
          subchr_amount = subchr_data.subchr_value
          subchr_type = subchr_data.subchr_type
          if (subchr_data.subchr_type === "Time") {
            subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time)
            subchr_totime = this.tmConv24(subchr_data.subchr_to_time)
          } else {
            subchr_date = subchr_data.subchr_date
          }
        }
      }

      this.setState({
        onlineservice_displaylabel: onlineservice_displaylabel,
        online_servicecharge_type: online_servicecharge_type,
        online_servicecharge_per: online_servicecharge_per,
        online_servicecharge_amount: online_servicecharge_amount,
        subchr_amount: subchr_amount,
        subchr_type: subchr_type,
        subchr_date: subchr_date,
        subchr_frmtime: subchr_frmtime,
        subchr_totime: subchr_totime,
      })
    })

    /* surcharge details - End */

    /* check order validation */
    var settingpostalParam = cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Yes" : "No"
    if (cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
      var promise = getResult("settingsPro", "No")
      promise.then((res) => {
        var settingResultZone = res.data.result_set
        this.setState({ zoneEnabled: parseInt(settingResultZone.client_outlet_zone) })
        this.setState({ zone_order_value_delivery_charge: settingResultZone.zone_order_value_delivery_charge }, function () {
          this.getCartDetails("First")
        })
        cookie.save("client_otp_verification_enable", settingResultZone.client_otp_verification_enable)
        cookie.save("client_otp_verification_via", settingResultZone.client_otp_verification_via)
      })
    } else {
      this.getCartDetails("First")
    }

    var zoneDetails = ""
    if (typeof cookie.load("orderZoneID") !== "undefined" && cookie.load("orderZoneID") !== "" && cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
      axios.get(APIURLv2 + "outlets/getZoneDetails/?app_id=" + APPID + "&availability=" + config.ids.deliveryId + "&ZoneId=" + cookie.load("orderZoneID") + "&outletId=" + cookie.load("orderOutletId")).then((res) => {
        if (res.data.status === "ok") {
          this.setState({ zoneDetails: res.data.result_set[0] })
        } else {
          alert("something went wrong")
          hashHistory.push("/")
          /* check */
        }
      })
    }
    /* load cart details */

    /* check Authentication */
    if (getResult("Auth") === false) {
      hashHistory.push("/sign-in")
    }

    /* check user informations  */
    //  alert(cookie.load('UserMobile'));
    if (getResult("UserDetails") === false) {
      cookie.save("isChecoutRedirect", "Yes")
      helper.showInfo(lang.user_info_missing)
      hashHistory.push("/my-account")
    }

    /* validate delivery details */
    if (cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
      if (getResult("DeliveryDetails") === false) {
        helper.showInfo(lang.delivery_info_missing)
        hashHistory.push("/delivery-date")
      }
    }

    /* validate cart details */
    /*
  Removed - page optimization
	axios.get(APIURL+'cartver2/contents?app_id='+APPID+"&customer_id="+cookie.load('UserId'))
		 .then(res => {
		  if(res.data.status === "ok"){
			 if(typeof res.data.result_set =="undefined")
			   {    helper.showInfo('Your cart is empty. Please add products.');
			        hashHistory.push("/");
			   }
		  }
		  });  */

    /* get offline payment statick block content */
    var stsaicResult = helper.getStaticBlock("offline-payment-information")
    stsaicResult.then((stTes) => {
      if (stTes.data.status === "ok") {
        this.setState({ offlineDesc: stTes.data.result_set[0].staticblocks_description })
      }
    })

    /*  Get master card logo - End */
    var masterResult = helper.getStaticBlock("master-card-logo-checkout")
    masterResult.then((stTes) => {
      if (stTes.data.status === "ok") {
        this.setState({ masterCardLogo: stTes.data.result_set[0].staticblocks_description })
      }
    })
  }

  componentWillMount() {
    var windowHeight = window.innerHeight
    this.setState({ windowHeight: windowHeight - 45 + "px" })
    this.setState({ windowHeightNormal: windowHeight + "px" })

    axios.get(APIURL + "customer/advocado_PDPA?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ pdpacontent: res.data.content })
      }
    })
  }
  componentDidMount() {
    loadAnalytics()
    fbPixelCustomEvent("InitiateCheckout", {})

    /* if( typeof cookie.load('promotion_code') !== "undefined" && cookie.load('promotion_code') !== "") {
		  $(".applyPromo").trigger("click");
	 } */
    const script = document.createElement("script")
    script.src = "https://cdn.omise.co/omise.js"
    script.async = true
    document.body.appendChild(script)

    if (cookie.load("defaultAvilablityId") === config.ids.dineinId) {
      this.dineInInfirmation()
    }

    if (typeof cookie.load("is_custom_redirect") !== "undefined" && cookie.load("is_custom_redirect") === "yes") {
      this.setState(cookie.load("promo_object"))
      this.setState({ promo_type: "add_products" })
    }
  }

  dineInInfirmation() {
    $(".alrt_overlay").remove()
    $(".custom_alert").remove()
    var alertheader = "Confirmation"
    var alrtmessage = "Please be informed to place the order when you are present at the outlet.<br/>All orders paid cannot be cancelled or altered."
    $("body").append(
      '<div class="alrt_overlay"></div><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        alertheader +
        '</div><div class="alert_body dine-in-alert-msg"> ' +
        alrtmessage +
        ' <div class="alt_btns"><a  class="btn btn-primary confirm_dine_in" id="alt1">Proceed</a><a  class="btn btn-info reject_dine_in"  >Close</a></div></div></div></div></div>'
    )

    var actheight = $(".alert_height").height()
    $(".custom_alert").height(actheight + 10)

    $(".confirm_dine_in").click(function (e) {
      $(".alrt_overlay, .custom_alert").remove()
    })

    $(".reject_dine_in").click(function (e) {
      hashHistory.push("/")
      $(".alrt_overlay, .custom_alert").remove()
      return false
    })
  }

  componentDidUpdate() {}

  /* post stripe account */
  onToken = (token) => {
    fbPixelCustomEvent("EnteredPaymentInfo", {})
    /*  load process html */
    this.setState({ paymentStatus: "ONLINE" })
    this.setState({ defaultClass: "chk_popup_bg" })
    $("#SwitchingError").modal("toggle")
    axios.get(APIURL + "callcenterapi/get_payment_reference_id?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        var paymentRef = res.data.payment_ref_id
        this.setState({ payment_ref_id: paymentRef })
        var orderOutlet_Id = cookie.load("orderOutletId")
        var postObject = {}
        postObject = {
          app_id: APPID,
          token: token.id,

          customer_id: cookie.load("UserId"),
          paid_amount: this.state.newVoucher != "1" ? (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2) : parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2),
          outlet_name: typeof cookie.load("orderOutletName") !== "undefined" ? cookie.load("orderOutletName") + " - " + paymentRef : "",
          payment_reference: config.stripe.stripeReference,
          outlet_id: orderOutlet_Id,
        }

        axios.post(APIURLPRO + "paymentV7/stripeTokenPay", qs.stringify(postObject)).then((res) => {
          //this.setState({status:""});
          if (res.data.status === "ok") {
            /* Reset poup message -  start */
            // console.log('paysuccess');

            this.setState({ paymentLoading: "/img/tick.png" })
            this.setState({ paymentGateway: "Stripe" })

            /* Reset poup message -  end  */

            var captureID = res.data.result_set.payment_reference_1
            this.postOrder(3, "", captureID)
          } else if (res.data.status === "error") {
            /* Reset poup message -  start */
            $("#SwitchingError").modal("toggle")
            this.setState({ defaultClass: "" })
            /* Reset poup message -  end  */
            helper.showInfo(res.data.message)
          }
        })
      }
    })
  }

  tmConv24(time24) {
    var ts = time24
    if (ts !== "") {
      var H = +ts.substr(0, 2)
      var h = H % 12 || 12
      h = h < 10 ? "0" + h : h
      var ampm = H < 12 ? " AM" : " PM"
      ts = h + ts.substr(2, 3) + ampm
    }
    return ts
  }

  /* stripe description  */
  stripeDescription() {
    //userName
    if (typeof cookie.load("UserLname") !== "undefined" && cookie.load("UserLname") !== "") {
      return cookie.load("UserFname") + " " + cookie.load("UserLname")
    } else {
      return cookie.load("UserLname")
    }
  }

  /* */
  onError = (err) => {
    // The main Paypal's script cannot be loaded or somethings block the loading of that script!
    console.log("Error!", err)
    this.setState({ paypalPayment: false })
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  }

  onSuccess = (payment) => {
    // Congratulation, it came here means everything's fine!
    console.log("The payment was succeeded!", payment)
    /* used process screen html show */
    cookie.save("orderPaymentMode", "PAYPAL")
    this.setState({ paymentStatus: "ONLINE" })
    this.setState({ defaultClass: "chk_popup_bg" })
    this.setState({ paypalPayment: true })
    $("#SwitchingError").modal("toggle")
    this.postOrder(2)
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  }

  onCancel = (data) => {
    // User pressed "cancel" or close Paypal's popup!
    console.log("The payment was cancelled!", data)
    this.setState({ paypalPayment: false })
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  }
  /**/

  /* check  delivery account and grand total amount */
  checkDeliveryAmount(subTotal = null, deliveryApplied = null) {
    /* update state valeu */
    // alert(deliveryApplied);

    //alert('check delivery');
    /* check free  delivery */
    // console.log(this.state.cartDetails);
    var freeDeliveryAmnt = parseFloat(this.state.settings.client_free_delivery_amount)
    var DeliveryAmnt = parseFloat(this.state.settings.client_delivery_surcharge)
    var cartSubtotal = subTotal === null ? parseFloat(this.state.cartDetails.cart_sub_total) : parseFloat(subTotal)
    //  alert(cartSubtotal);
    /* get GST value */
    var dgstSubToatl = cartSubtotal

    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00") {
      var dgstAmount = outlet_tax_surcharge
    } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00") {
      var dgstAmount = "0.00"
    } else {
      var dgstAmount = parseFloat(this.state.settings.client_tax_surcharge)
    }

    // var dgstAmount = parseFloat(this.state.settings.client_tax_surcharge);
    /*var ddisplayGst = helper.getGstValue(dgstAmount,dgstSubToatl,'format');*/

    if ((this.state.cartDetails.cart_availability_id === config.ids.deliveryId && (DeliveryAmnt > 0 || parseFloat(this.state.additnl_deliveryAmnt_cal) > 0) && freeDeliveryAmnt > 0 && this.state.originalSubTotal >= freeDeliveryAmnt) || deliveryApplied === "Yes") {
      var dgstSubToatl_delivry = dgstSubToatl
    } else if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      var dgstSubToatl_delivry = parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(DeliveryAmnt) + parseFloat(dgstSubToatl)
    } else {
      var dgstSubToatl_delivry = dgstSubToatl
    }

    if (parseFloat(this.state.subchr_amount) > 0) {
      dgstSubToatl_delivry = parseFloat(dgstSubToatl_delivry) + parseFloat(this.state.subchr_amount)
    }

    var service_charge_amount = this.state.cartDetails.service_charge_amount !== "" && this.state.cartDetails.service_charge_amount !== undefined ? parseFloat(this.state.cartDetails.service_charge_amount) : 0

    if (parseFloat(service_charge_amount) > 0) {
      dgstSubToatl_delivry = parseFloat(dgstSubToatl_delivry) + parseFloat(service_charge_amount)
    }

    var ddisplayGst = helper.getGstValue(dgstAmount, dgstSubToatl_delivry, "format")
    /* End - Gst value */

    /*var grandTotal = cartSubtotal + parseFloat(this.state.settings.additional_delivery_charge) + parseFloat(ddisplayGst);*/

    /*var grandTotal = cartSubtotal + parseFloat(this.state.additnl_deliveryAmnt_cal) + parseFloat(ddisplayGst);

	if( (this.state.cartDetails.cart_availability_id === config.ids.deliveryId &&  (DeliveryAmnt  > 0 || parseFloat(this.state.additnl_deliveryAmnt_cal) > 0) && freeDeliveryAmnt > 0   && grandTotal >= freeDeliveryAmnt) || deliveryApplied === "Yes")
		 { */

    if ((this.state.cartDetails.cart_availability_id === config.ids.deliveryId && (DeliveryAmnt > 0 || parseFloat(this.state.additnl_deliveryAmnt_cal) > 0) && freeDeliveryAmnt > 0 && this.state.originalSubTotal >= freeDeliveryAmnt) || deliveryApplied === "Yes") {
      //// alert('  free delivery // ' + grandTotal + "//"+dgstSubToatl+"//"+subTotal);
      /* if less the 0.50 - start */
      var grandTotal = cartSubtotal + parseFloat(ddisplayGst) + parseFloat(this.state.subchr_amount)

      if (grandTotal < parseFloat("0.50") && grandTotal > 0) {
        grandTotal = parseFloat("0.50")
        this.setState({ stripeMinPay: "Yes" })
      } else {
        this.setState({ stripeMinPay: "No" })
      }
      /* if less then  0.50 - End */
      this.setState({ cartDetails: Object.assign({}, this.state.cartDetails, { cart_delivery_charge: 0, cart_grand_total: grandTotal }) })
      this.setState({ additnl_deliveryAmnt_cal: 0 })
      this.setState({ isFreeDelivery: "Yes" })
    } else {
      this.setState({ isFreeDelivery: "No" })
      // console.log('not free delivery');
    }
  }

  /* Load modifier arr */
  modifierArr() {
    return []
  }

  /* add zero */
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString()
  }

  /*calculate service charge*/

  cal_service_charge(payment_mode) {
    let service_charge_amount = 0
    let onlineServicechargePer = 0
    let originalSubTotal = parseFloat(this.state.originalSubTotal)

    console.log(payment_mode, $('input[name="payment"]').length, this.state.settings.client_cod_enable)

    if ((payment_mode == 1 || payment_mode == 2 || payment_mode == 3 || payment_mode == 5 || (payment_mode === "initial" && !$('input[name="payment"]').length) || payment_mode === "change") && originalSubTotal > 0) {
      if (this.state.online_servicecharge_type === "fixed") {
        onlineServicechargePer = 0
        var serChrAmount = this.state.online_servicecharge_amount !== "" ? parseFloat(this.state.online_servicecharge_amount) : 0
        service_charge_amount = serChrAmount.toFixed(2)
      } else {
        onlineServicechargePer = this.state.online_servicecharge_per
        if (parseFloat(this.state.online_servicecharge_per) > 0) {
          service_charge_amount = helper.getServicecharge(this.state.online_servicecharge_per, originalSubTotal, "format")
        }
      }
    }
    console.log("well")
    this.setState({ cartDetails: Object.assign({}, this.state.cartDetails, { service_charge: onlineServicechargePer, service_charge_amount: service_charge_amount }) })
  }

  cal_service_charge_old(payment_mode) {
    let service_charge_amount = 0
    let onlineServicechargePer = 0
    let grand_total = parseFloat(this.state.cartDetails.cart_grand_total)

    console.log(payment_mode, $('input[name="payment"]').length, this.state.settings.client_cod_enable)

    let bool_flag =
      (parseInt(this.state.settings.client_cod_enable) === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) ||
      (parseInt(this.state.settings.client_cod_enable) === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_cod_availability) === 1)

    console.log("bool_flag", bool_flag)

    /*|| (payment_mode === 'initial' && !$('input[name="payment"]').length && parseInt(this.state.settings.client_cod_enable) !== 1) */

    /*if(  (
		
		(payment_mode == 2 || payment_mode == 3) 
		
		|| (payment_mode === 'initial' && !$('input[name="payment"]').length && !bool_flag) 
		
		|| (payment_mode === 'change' && $('input[name="payment"]:checked').val() !== 'CASH')) && grand_total > 0) { */

    if ((payment_mode == 1 || payment_mode == 2 || payment_mode == 3 || payment_mode == 5 || (payment_mode === "initial" && !$('input[name="payment"]').length) || payment_mode === "change") && grand_total > 0) {
      /*console.log(this.state.settings.client_online_service_charge);
			if(parseFloat(this.state.settings.client_online_service_charge) > 0) {
				service_charge_amount = helper.getServicecharge(this.state.settings.client_online_service_charge,grand_total,'format');
			}*/

      if (this.state.online_servicecharge_type === "fixed") {
        onlineServicechargePer = 0
        var serChrAmount = this.state.online_servicecharge_amount !== "" ? parseFloat(this.state.online_servicecharge_amount) : 0
        service_charge_amount = serChrAmount.toFixed(2)
      } else {
        onlineServicechargePer = this.state.online_servicecharge_per
        if (parseFloat(this.state.online_servicecharge_per) > 0) {
          service_charge_amount = helper.getServicecharge(this.state.online_servicecharge_per, grand_total, "format")
        }
      }
    }

    this.setState({ cartDetails: Object.assign({}, this.state.cartDetails, { service_charge: onlineServicechargePer, service_charge_amount: service_charge_amount }) })

    /*this.setState({cartDetails: Object.assign({}, this.state.cartDetails, {service_charge: this.state.settings.client_online_service_charge,service_charge_amount:service_charge_amount})});*/
  }

  makeid(length) {
    var result = ""
    var characters = "0123456789"
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  /* this fuction used to post order to biz panel */
  postOrder(paymentMode, validation, captureID) {
    var products = []
    var row = this.state.cartItems.map(
      function (item, i) {
        var modifierdata = []
        var comboData = []
        var ComboLen = item.set_menu_component.length
        var modifierLen = item.modifiers.length
        if (item.cart_item_type === "Modifier" && modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = []
            modifierVal.push({
              modifier_value_id: item.modifiers[l].modifiers_values[0].cart_modifier_id,
              modifier_value_qty: item.modifiers[l].modifiers_values[0].cart_modifier_qty,
              modifier_value_name: item.modifiers[l].modifiers_values[0].cart_modifier_name,
              modifier_value_price: item.modifiers[l].modifiers_values[0].cart_modifier_price,
            })
            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: item.modifiers[l].cart_modifier_name,
              modifiers_values: modifierVal,
            })
          }
        } else if (item.cart_item_type === "Component" && ComboLen > 0) {
          /* combo product setup */

          for (var m = 0; m < ComboLen; m++) {
            var combomodifierdata = []
            //console.log(item.set_menu_component[m].product_details);
            /* build  modifier array */
            var comboModifier = item.set_menu_component[m].product_details[0].modifiers

            var combomodifierdata = []
            if (comboModifier.length > 0) {
              /* new version product setup */

              for (var p = 0; p < comboModifier.length; p++) {
                // alert(comboModifier[p].modifiers_values[0].cart_modifier_id); return false;
                var comboModifierVal = []
                comboModifierVal.push({
                  modifier_value_id: comboModifier[p].modifiers_values[0].cart_modifier_id,
                  modifier_value_qty: comboModifier[p].modifiers_values[0].cart_modifier_qty,
                  modifier_value_name: comboModifier[p].modifiers_values[0].cart_modifier_name,
                  modifier_value_price: comboModifier[p].modifiers_values[0].cart_modifier_price,
                })
                combomodifierdata.push({
                  modifier_id: comboModifier[p].cart_modifier_id,
                  modifier_name: comboModifier[p].cart_modifier_name,
                  modifiers_values: comboModifierVal,
                })
              }
            }

            /*	comborVal.push( { /* commented for new version setup  /
				product_id:item.set_menu_component[m].product_details[0].cart_menu_component_product_id ,
				product_name:item.set_menu_component[m].product_details[0].cart_menu_component_product_name,
				product_sku:item.set_menu_component[m].product_details[0].cart_menu_component_product_sku,
				modifiers:combomodifierdata});
				comboData.push( {
							menu_component_id:item.set_menu_component[m].menu_component_id,
							menu_component_name:item.set_menu_component[m].menu_component_name,
							product_details:comborVal}) */
            //console.log(item.set_menu_component[m].product_details);

            var comborVal = []
            var comboProductDetailslen = item.set_menu_component[m].product_details.length
            if (comboProductDetailslen > 0) {
              for (var j = 0, lengthComboPro = comboProductDetailslen; j < lengthComboPro; j++) {
                comborVal.push({
                  product_id: item.set_menu_component[m].product_details[j].cart_menu_component_product_id,
                  product_name: item.set_menu_component[m].product_details[j].cart_menu_component_product_name,
                  product_sku: item.set_menu_component[m].product_details[j].cart_menu_component_product_sku,
                  product_qty: item.set_menu_component[m].product_details[j].cart_menu_component_product_qty,
                  product_price: item.set_menu_component[m].product_details[j].cart_menu_component_product_price,
                  modifiers: combomodifierdata,
                })
              }
            }

            comboData.push({
              menu_component_id: item.set_menu_component[m].menu_component_id,
              menu_component_name: item.set_menu_component[m].menu_component_name,
              product_details: comborVal,
            })
          }
        }

        products.push({
          product_name: item.cart_item_product_name,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          product_sku: item.cart_item_product_sku,
          product_image: item.cart_item_product_image,
          product_id: item.cart_item_product_id,
          product_qty: item.cart_item_qty,
          product_cost_price: item.cart_product_price,
          product_product_voucher: item.cart_product_voucher,
          product_voucher_expiry_date: item.product_voucher_expiry_date,
          product_voucher_points: item.product_voucher_points,
          voucher_gift_name: item.cart_item_product_voucher_gift_name,
          voucher_gift_email: item.cart_item_product_voucher_gift_email,
          voucher_gift_message: item.cart_item_product_voucher_gift_message,
          order_item_id: item.cart_voucher_order_item_id != "0" ? item.cart_voucher_order_item_id : "",
          condiments: "",
          modifiers: modifierdata,
          bakers_modifiers: "",
          menu_set_components: comboData,
          baby_pack: "",
        })

        return products
      }.bind(this)
    )

    //console.log(products); return false;

    /* if  merge order date */
    var orderDate = ""
    if (cookie.load("defaultAvilablityId") !== config.ids.dineinId) {
      var cookieDate = cookie.load("DeliveryDate")
      var splitedDate = cookieDate.split("/")
      //alert(splitedDate);  return false;
      var selDate = this.pad(splitedDate[0])
      var selMonth = this.pad(splitedDate[1])
      var selYear = splitedDate[2]
      var formatedDate = selYear + "-" + selMonth + "-" + selDate
      //alert(formatedDate);
      var orderTime = typeof cookie.load("DeliveryTime") === "undefined" || cookie.load("DeliveryTime") === "" ? "" : cookie.load("DeliveryTime")
      orderDate = formatedDate + " " + orderTime //dateFormat( cookie.load('DeliveryTime'),"yyyy-m-d H:MM");

      /* timeslot calculation */

      var order_pickup_time_slot_from = ""
      var order_pickup_time_slot_to = ""
      var order_is_timeslot = "No"

      if (cookie.load("isSletedTimeSlot") === "Yes") {
        var order_is_timeslot = "Yes"
        var cookieSlot = cookie.load("DeliveryTimeSlot")
        var splitedSlot = cookieSlot.split("-")
        order_pickup_time_slot_from = splitedSlot[0]
        order_pickup_time_slot_to = splitedSlot[1]
      }
    }

    /* insert customner details */
    if (validation !== "Yes") {
      var addressObject = {}
      var customerAddressId = 0
      addressObject = {
        app_id: APPID,
        customer_first_name: cookie.load("UserFname"),
        customer_last_name: cookie.load("UserLname"),
        customer_phone: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: cookie.load("orderDeliveryAddress"),
        customer_postal_code: cookie.load("orderPostalCode"),
        customer_address_name: cookie.load("unitNoOne"),
        customer_address_name2: cookie.load("unitNoTwo"),
        refrence: cookie.load("UserId"),
      }
      if (cookie.load("defaultAvilablityId") === config.ids.deliveryId) {
        axios.post(APIURL + "customer/secondary_address_add", qs.stringify(addressObject)).then((res) => {
          if (res.data.status === "ok") {
            customerAddressId = res.data.insert_id
          }
        })
      }
    }

    if (this.state.promoType !== "PROMO" && cookie.load("defaultAvilablityId") === config.ids.deliveryId && parseFloat(this.state.cartDetails.cart_sub_total) > 0 && parseFloat(this.state.cartDetails.cart_sub_total) < parseFloat(this.state.settings.callcnter_min_amount)) {
      helper.showInfo("A minimum order value of " + currency + this.state.settings.callcnter_min_amount + ",in order to checkout the cart. Current cart value is " + currency + this.state.cartDetails.cart_sub_total + ".")
      hashHistory.push("/cart")
      return false
    }

    /* End of customer address add */

    var GetSubToalAmount = parseFloat(helper.getsubTotal(this.state.cartDetails.cart_sub_total, this.state.originalSubTotal, this.state.promoApplied, this.state.reedemptionApplied)).toFixed(2)

    // console.log(products);  return false;

    /* get cart details */

    var deliveryAmnt = helper.getDeliveryCharge(this.state.promoApplied, this.state.promoAppliedDelivery, this.state.originalDeliveryAmnt, this.state.isFreeDelivery, this.state.isFreeDelivery)

    /*var AdditionalDelivery = this.state.settings.additional_delivery_charge;*/
    var AdditionalDelivery = helper.getAdditionalDeliveryCharge(this.state.promoApplied, this.state.promoAppliedDelivery, this.state.additnl_deliveryAmnt_cal, this.state.isFreeDelivery)

    /* get GST amount */
    var orderGtSubToatl = parseFloat(GetSubToalAmount)

    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00") {
      var orderGstAmount = outlet_tax_surcharge
    } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00") {
      var orderGstAmount = "0.00"
    } else {
      var orderGstAmount = parseFloat(this.state.settings.client_tax_surcharge)
    }

    //var orderGstAmount =  parseFloat(this.state.settings.client_tax_surcharge);
    /*var orderDisplayGst = helper.getGstValue(orderGstAmount,orderGtSubToatl,'format');*/

    var orderGtSubToatl_delivry = parseFloat(orderGtSubToatl) + parseFloat(deliveryAmnt) + parseFloat(AdditionalDelivery) + parseFloat(this.state.subchr_amount) + parseFloat(this.state.cartDetails.service_charge_amount)
    var orderDisplayGst = helper.getGstValue(orderGstAmount, orderGtSubToatl_delivry, "format")

    var calc_grand_total = parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)

    var ServiceChargeText = this.state.onlineservice_displaylabel !== "" ? this.state.onlineservice_displaylabel : "Service Charge"

    /*Calculate service charge End*/
    calc_grand_total = calc_grand_total.toFixed(2)

    var postObject = {}
    var paymentGatewayDetails = null
    if (this.state.koomipayPayment) {
      paymentGatewayDetails = { payment_type: "Koomipay", payment_status: "Success" }
    }
    postObject = {
      /* cart details */
      app_id: APPID,
      availability_id: cookie.load("defaultAvilablityId"),
      sub_total: this.state.originalSubTotal,
      grand_total: this.isApplyRewardOnGrandTotal() === true ? this.state.finalApplyRewardsOriginalGrandTotal : this.state.newVoucher != "1" ? calc_grand_total : this.state.originalSubTotal,

      order_subcharge_amount: this.state.newVoucher != "1" ? this.state.subchr_amount : "",
      order_service_flage: "yes",
      order_service_charge: this.state.newVoucher != "1" ? this.state.cartDetails.service_charge : "",
      order_service_charge_amount: this.state.newVoucher != "1" ? this.state.cartDetails.service_charge_amount : "",
      order_servicecharge_displaylabel: ServiceChargeText,

      outlet_id: cookie.load("orderOutletId"),
      zone_id: this.state.zoneDetails != "" && typeof this.state.zoneDetails != "undefined" ? this.state.zoneDetails["oza_outlet_zone_id"] : "",
      table_number: cookie.load("orderTableNo"),
      order_status: 1,
      order_source: "Web",
      order_date: orderDate,
      order_pickup_time_slot_from: order_pickup_time_slot_from,
      order_pickup_time_slot_to: order_pickup_time_slot_to,
      order_is_timeslot: order_is_timeslot,
      order_remarks: cookie.load("product_remarks"),
      products: JSON.stringify(products),

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      customer_address_id: customerAddressId,
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: cookie.load("orderDeliveryAddress"),
      customer_address_line2: "",
      customer_postal_code: cookie.load("orderPostalCode"),
      customer_unit_no1: cookie.load("unitNoOne"),
      customer_unit_no2: cookie.load("unitNoTwo"),
      customer_tower_number: typeof cookie.load("towerNo") === "undefined" ? "" : cookie.load("towerNo"),

      /* Payment */
      payment_mode: paymentMode /*paymentMode, */,
      payment_reference: config.stripe.stripeReference,
      order_payment_getway_type: this.state.koomipayPayment ? "Koomipay" : "",
      order_payment_getway_status: "",
      payment_getway_details: paymentGatewayDetails,
      order_local_payment_reference: this.state.payment_ref_id,

      /* additional paras */
      delivery_charge: this.state.newVoucher != "1" ? parseFloat(deliveryAmnt) : "",
      additional_delivery: this.state.newVoucher != "1" ? parseFloat(AdditionalDelivery) : "",
      order_tat_time: typeof cookie.load("orderTAT") === "undefined" ? "" : cookie.load("orderTAT"),
      tax_charge: this.state.newVoucher != "1" ? orderGstAmount : "",
      order_tax_calculate_amount: this.state.newVoucher != "1" ? orderDisplayGst : "",

      order_tax_charge_inclusive: typeof cookie.load("gstInclusivePercent") === "undefined" ? "" : cookie.load("gstInclusivePercent"),
      order_tax_calculate_amount_inclusive: typeof cookie.load("gstInclusiveValue") === "undefined" ? "" : cookie.load("gstInclusiveValue"),

      order_data_encode: "Yes",
      ispromobuddyOrder: typeof cookie.load("ispromobuddyOrder") !== "undefined" && cookie.load("ispromobuddyOrder") === "Yes" ? cookie.load("ispromobuddyOrder") : "",

      /* validation purpose*/
      promotion_applied_text: this.state.promoApplied,
      promo_applied_delivery_text: this.state.promoAppliedDelivery,
      is_freeDelivery_text: this.state.isFreeDelivery,

      /* discount */
      order_discount_applied: "",
      order_discount_amount: "",
      order_discount_type: "",
      reward_point_status: this.state.promoApplied,

      outlet_tax_option: cookie.load("outlet_tax_option"),
      outlet_tax_surcharge: cookie.load("outlet_tax_surcharge"),
      outlet_tax_surcharge_inclusive: cookie.load("outlet_tax_surcharge_inclusive"),
      pin: this.state.advocadopin,
      omise_paynow_status: this.state.omisePayNowStatus,
      omise_paynow_capture_id: this.state.payNowCaptureId,
      razerpay_order_id: this.state.razerpayOrderId,
      is_voucher: this.state.newVoucher == "1" ? 1 : "",
      is_paypal_payment_success: this.state.paypalPayment,
    }
    //console.log(postObject);
    /* check  validation */
    if (validation === "Yes") {
      postObject["validation_only"] = "Yes"
    }

    /*  promo code object - start */
    if (this.state.promoApplied === "Yes" && this.state.promoType === "PROMO") {
      postObject["coupon_applied"] = "Yes"
      postObject["promo_code"] = this.state.promocode
      //	 postObject['sub_total'] = this.state.originalSubTotal;
      postObject["cart_quantity"] = this.state.promoQty
      postObject["category_id"] = this.getPromoCategory()
      postObject["coupon_amount"] = this.state.promoAmount
      postObject["promotion_delivery_charge_applied"] = this.state.promoAppliedDelivery
      //promotion_delivery_charge_applied

      // console.log(postObject); return false;
    } else if (this.state.promoApplied === "Yes" && this.state.promoType === "POINTS") {
      postObject["redeem_point"] = this.state.promoPoints //redeem_applied
      postObject["redeem_applied"] = "Yes" //redeem_amount
      postObject["redeem_amount"] = this.state.promoAmount
      postObject["redeem_amount_apply_on_grand_total"] = this.isApplyRewardOnGrandTotal()
      postObject["redeem_amount_apply_on_grand_total_amount"] = this.state.finalApplyRewardsOriginalGrandTotal
      postObject["redeem_amount_apply_on_original_grand_total_amount"] = this.state.applyRewardsOriginalGrandTotal
    }

    var advocadopinTxt = this.state.advocadopin
    var promoAppliedTxt = this.state.promoApplied
    var promoTypeTxt = this.state.promoType

    if (validation !== "Yes" && advocadopinTxt === "") {
      postObject["reward_point_status"] = "Yes"
    }

    var gift_option = "No"
    if (this.state.gift_option === true) {
      gift_option = "Yes"
      postObject["order_recipient_name"] = this.state.recipient_name
      postObject["order_recipient_contact_no"] = this.state.recipient_contact_no
      postObject["order_gift_message"] = this.state.gift_message
    }
    postObject["order_customer_send_gift"] = gift_option

    //this.setState({status:"Loading"});
    /*var OrderAPIUrl = APIURLPRO+"orders/submit_order";
	 	if((captureID !==undefined && captureID !=='') || (advocadopinTxt === '')){
			 OrderAPIUrl = APIURLPRO+"ordersv1/submit_order";
		}*/
    //		var OrderAPIUrl = APIURLPRO+"testapi/ordersv1/submit_order";

    var OrderAPIUrl = APIURLPRO + "ordersv2/submit_order"

    if (this.state.koomipayPayment) {
      if (validation === "Yes") {
        postObject["order_id_for_koomipay"] = "NINJA_" + this.makeid(10)
      } else {
        postObject["order_id_for_koomipay"] = cookie.load("orderIdForKoomipay")
      }
      cookie.save("orderIdForKoomipay", postObject["order_id_for_koomipay"], { path: "/" })
      if (captureID) {
        postObject["order_koomipay_reference_id"] = captureID
      }
    }

    axios.post(OrderAPIUrl, qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok") {
        const validatedOrder = postObject
        this.setState({ validatedOrder })
        if (validation !== "Yes") {
          if (captureID !== undefined && captureID !== "") {
            if (this.state.omisePayment == 1) {
              this.captureOmiseAmount(captureID, res.data.common.order_primary_id, res.data.common.local_order_no)
            } else if (this.state.koomipayPayment) {
              this.captureKoomipayAmount(captureID, res.data.common.order_primary_id, res.data.common.local_order_no)
            } else {
              this.captureAmount(captureID, res.data.common.order_primary_id, res.data.common.local_order_no)
            }
          } else {
            this.setState({ orderLoading: "/img/tick.png" })
            this.setState({ status: "ok" })
            this.destroyCart()
            this.deleteOrderCookie()
            setTimeout(
              function () {
                $("#SwitchingError").modal("toggle")
                hashHistory.push("/order-success/" + res.data.common.local_order_no)
                this.sentchatbox(res.data.common.order_primary_id)
              }.bind(this),
              500
            )
          }
        } else {
          this.setState({ status: "ok" })

          document.getElementById("loadIcon").style.display = "none"

          return true
        }
      } else if (res.data.status === "error") {
        var errorMsg = ""
        if (res.data.message !== "" && typeof res.data.message != undefined && typeof res.data.message != "undefined") {
          errorMsg = res.data.message
        } else if (res.data.form_error != "" && typeof res.data.form_error != undefined && typeof res.data.form_error != "undefined") {
          errorMsg = htmlToReactParserTo.parse(res.data.form_error)
        }

        if (validation !== "Yes") {
          $("#SwitchingError").modal("toggle")
          document.getElementById("PayProcess").style.display = "none"
          this.setState({ defaultClass: "" })
          this.setState({ status: "error" })
          /*helper.showInfo(res.data.status);*/
          var msgTxt = res.data.message != "" ? res.data.message : res.data.status
          helper.showInfo(msgTxt)
        } else {
          if (typeof res.data.order_count !== undefined && res.data.order_count === 1) {
            helper.showInfo(res.data.message)
            if (this.state.settings.client_delivery_time_setting_enable === "1") {
              hashHistory.push("/delivery-date-advanced")
            } else if (this.state.settings.client_delivery_time_setting_enable === "1") {
              hashHistory.push("/delivery-date-new")
            } else {
              hashHistory.push("/delivery-date")
            }
          } else {
            this.orderValidationFail(errorMsg)
          }
        }
      } else if (res.data.status == "revel_cart_error") {
        /*helper.showInfo(res.data.status);*/
        var msgTxt = res.data.message != "" ? res.data.message : res.data.status
        helper.showInfo(msgTxt)

        hashHistory.push("/cart")
      } else {
        this.orderValidationFail()
      }
    })
  }

  sentchatbox(orderPrimaryId) {
    var postObject = {
      app_id: APPID,
      order_primary_id: orderPrimaryId,
      customer_id: cookie.load("UserId"),
    }
    axios.post(APIURL + "chatbot/order_notify", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok") {
      }
    })
  }

  /* sucess page */
  showSuccessPage(localOrderNo, orderPrimaryId) {
    this.setState({ orderLoading: "/img/tick.png" })
    this.setState({ status: "ok" })
    this.sentchatbox(orderPrimaryId)
    this.destroyCart()
    this.deleteOrderCookie()
    setTimeout(
      function () {
        $("#SwitchingError").modal("hide")
        hashHistory.push("/order-success/" + localOrderNo)
      }.bind(this),
      500
    )
  }

  /* Capture amount */
  captureKoomipayAmount(captureID, orderPrimaryId, localOrderNo) {
    var orderOutlet_Id = cookie.load("orderOutletId")
    const captureObject = {
      payment_id: captureID,
      order_id: orderPrimaryId,
      customer_id: cookie.load("UserId"),
      app_id: config.ids.appId,
      outlet_id: orderOutlet_Id,
      local_order_no: localOrderNo
    }
    axios
      .post(APIURLPRO + "koomipay/capture", qs.stringify(captureObject))
      .then((res) => {
        // need handle fail to capture later
        this.showSuccessPage(localOrderNo, orderPrimaryId)
      })
      .catch((err) => {
        console.log(err)
        this.showSuccessPage(localOrderNo, orderPrimaryId)
      })
  }

  captureAmount(captureID, orderPrimaryId, localOrderNo) {
    var orderOutlet_Id = cookie.load("orderOutletId")
    var cabtureObjects = {}
    var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo
    cabtureObjects = {
      payment_reference: config.stripe.stripeReference,
      customer_id: cookie.load("UserId"),
      app_id: config.ids.appId,
      token: captureID,
      order_id: orderPrimaryId,
      outlet_id: orderOutlet_Id,
      paid_amount: this.state.newVoucher != "1" ? (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2) : (parseFloat(this.state.cartDetails.cart_sub_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2),
    }

    axios
      .post(APIURLPRO + "paymentV7/captureAmount", qs.stringify(cabtureObjects))
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.showSuccessPage(localOrderNo, orderPrimaryId)
        } else if (captureRes.data.status === "error") {
          $("#SwitchingError").modal("toggle")
          document.getElementById("PayProcess").style.display = "none"
          this.setState({ defaultClass: "" })
          this.setState({ status: "error" })
          /*helper.showInfo(res.data.status);*/
          var msgTxt = res.data.message != "" ? res.data.message : res.data.status
          helper.showInfo(msgTxt)
        } else {
        }
      })
      .catch((error) => {})
  }

  /* check order validation */
  orderValidationFail(errorMsg = null) {
    if (errorMsg !== null) {
      helper.showInfo(errorMsg, "Alert")
    } else {
      helper.showInfo("Something went wrong. Your order details or personal information is wrong. Please check and try again.")
    }
    var isTempUser = parseInt(cookie.load("isTempUser"))
    if (isTempUser === 1) {
      hashHistory.push("/")
    } else {
      hashHistory.push("/my-account")
    }
  }
  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    cookie.save("cartValue", "No")
    cookie.remove("orderPaymentMode")
    cookie.remove("orderOutletId")
    cookie.remove("orderTableNo")
    cookie.remove("product_remarks")
    cookie.remove("orderOutletName")
    cookie.remove("orderOutletId")
    cookie.remove("orderZoneID")
    cookie.remove("promotion_code")
    /* Delivery avilablity */
    cookie.remove("DeliveryDate")
    cookie.remove("DeliveryTime")
    cookie.remove("isSletedTimeSlot")
    cookie.remove("DeliveryTimeSlot") //
    cookie.remove("DeliveryTimeSlotFormated")
    cookie.remove("unitNoOne")
    cookie.remove("unitNoTwo") //
    cookie.remove("towerNo")

    cookie.remove("outlet_tax_option")
    cookie.remove("outlet_tax_surcharge")
    cookie.remove("outlet_tax_surcharge_inclusive")
    cookie.remove("tempPromoCode")
    cookie.remove("tempPoints")
  }

  /* this function used to delte all  cart items */
  destroyCart() {
    // var params = new URLSearchParams();
    //params.append('app_id', config.ids.appId);
    //params.append('customer_id',cookie.load('UserId'));
    var postObject = {}
    postObject = { app_id: config.ids.appId }
    postObject["customer_id"] = cookie.load("UserId")
    axios.post(APIURL + "cart/destroy", qs.stringify(postObject))
  }

  /* this  function used get cart content details */
  getCartDetails(sts, promoreload = false) {
    /* API CALL FOR BANNERS */
    //customerParam = "&customer_id="+cookie.load('UserId');
    var zoneparam = ""
    if (cookie.load("orderZoneID") !== "" && typeof cookie.load("orderZoneID") !== undefined && typeof cookie.load("orderZoneID") !== "undefined") {
      zoneparam = "&zone_id=" + cookie.load("orderZoneID")
    }
    var deliveryDate = ""
    var deliveryTime = cookie.load("DeliveryTime") !== undefined ? cookie.load("DeliveryTime") : ""
    var deliveryDateTxt = cookie.load("DeliveryDate") !== undefined ? cookie.load("DeliveryDate") : ""
    if (deliveryDateTxt != "") {
      var deliveryDateArr = deliveryDateTxt.split("/")
      deliveryDate = deliveryDateArr[2] + "-" + deliveryDateArr[1] + "-" + deliveryDateArr[0]
    }
    var postalCodeWhere = ""
    if (typeof cookie.load("orderPostalCode") !== "undefined") {
      postalCodeWhere = "&postal_code=" + cookie.load("orderPostalCode")
    }
    axios.get(APIURL + "cartver2/contents?status=A&app_id=" + APPID + "&customer_id=" + cookie.load("UserId") + zoneparam + "&orderdate=" + deliveryDate + "&ordertime=" + deliveryTime + "&stockvalide=yes" + postalCodeWhere).then((res) => {
      if (res.data.status === "ok") {
        if (typeof res.data.result_set == "undefined") {
          helper.showInfo("Your cart is empty. Please add products.")
          hashHistory.push("/")
        }

        var cartDetails = res.data.result_set.cart_details
        var cartItems = res.data.result_set.cart_items

        if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zone_order_value_delivery_charge === "1" && cartDetails["cart_zone_delivery_charge"] > 0) {
          var zone_Details = this.state.zoneDetails
          zone_Details["zone_delivery_charge"] = cartDetails["cart_zone_delivery_charge"]
          this.setState({ zoneDetails: zone_Details })
        }

        if (this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zoneDetails["zone_delivery_charge"] > 0) {
          cartDetails["cart_delivery_charge"] = this.state.zoneDetails["zone_delivery_charge"]
          cartDetails["cart_grand_total"] = parseFloat(cartDetails["cart_sub_total"]) + parseFloat(this.state.zoneDetails["zone_delivery_charge"])
        }
        this.setState({ cartDetails: cartDetails, cartItems: cartItems }, function () {
          this.findVoucherProduct()
        })

        var originalSubTotalAmt = cartDetails.cart_sub_total

        if (sts == "First") {
          this.setState({
            originalSubTotal: cartDetails.cart_sub_total,
            originalGrandTotal: cartDetails.cart_grand_total,
            originalDeliveryAmnt: cartDetails.cart_delivery_charge,
          })

          this.setState({ status: "Loading" })

          /* get customer reward points */
          var inputKeys = ["promotionwithoutuqc", "reward", "testtxt"]
          inputKeys = JSON.stringify(inputKeys)
          axios.get(APIURL + "reports/activity_counts1?app_id=" + APPID + "&status=A&customer_id=" + cookie.load("UserId") + "&act_arr=" + inputKeys).then((res) => {
            if (res.data.status === "ok") {
              this.setState({ customerPoints: res.data.result_set.reward_ponits, promotion_count: res.data.result_set.promotionwithoutuqc })
            }
          })

          /* get customer points */
          axios.get(APIURL + "/customer/customerdetail?app_id=" + APPID + "&refrence=" + cookie.load("UserId")).then((res) => {
            if (res.data.status === "ok") {
              this.setState({
                customers_is_anonymous: parseFloat(res.data.result_set.customers_is_anonymous),
                customerAdvocadoPoints: parseFloat(res.data.result_set.customer_reward_point),
                advocado_id: res.data.result_set.customer_advocado_id,
                enable_advocado: res.data.result_set.enable_advocado,
                advocado_cashbackPercent: typeof res.data.result_set.cashbackPercent !== "undefined" ? res.data.result_set.cashbackPercent : 0,
              })
            }
          })
        }

        /* get settings values */
        var postalParam = cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Yes" : "No"
        var outletParam = cookie.load("orderOutletId") !== "" && typeof cookie.load("orderOutletId") !== undefined ? "&outletid=" + cookie.load("orderOutletId") : ""

        var promise = getResult("settingsPro", postalParam + "&availability=" + cookie.load("defaultAvilablityId") + outletParam)
        promise.then((res) => {
          var settingsDet = res.data.result_set

          if ((this.state.zoneEnabled === 1 && cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.state.zoneDetails["zone_delivery_charge"] > 0) || this.state.zoneDetails["zone_min_amount"] > 0) {
            if (typeof this.state.zoneDetails === "undefined" || this.state.zoneDetails === "") {
              alert("something went wrong")
              hashHistory.push("")
            }

            settingsDet["callcnter_min_amount"] = this.state.zoneDetails["zone_min_amount"]
            settingsDet["client_free_delivery_amount"] = this.state.zoneDetails["zone_free_delivery"]
            settingsDet["client_delivery_surcharge"] = this.state.zoneDetails["zone_delivery_charge"]
            settingsDet["additional_delivery_charge"] = this.state.zoneDetails["zone_additional_delivery_charge"]
          }

          if (sts == "First") {
            var omiseEnable = parseInt(settingsDet.client_omise_enable)
            var stripeEnable = parseInt(settingsDet.client_stripe_enable)
            var codEnable = parseInt(settingsDet.client_cod_enable)
            var stripeAvbl = parseInt(settingsDet.client_stripe_availability)
            var codAvbl = parseInt(settingsDet.client_cod_availability)

            if ((codEnable === 0 || codAvbl === 0) && (stripeEnable === 0 || stripeAvbl === 0) && omiseEnable === 1) {
              this.setState({ omisePayment: 1 })
            }
          }

          this.setState({ settings: settingsDet })

          var AddDelgrandTotal = this.state.newVoucher != "1" ? parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.subchr_amount) : parseFloat(this.state.cartDetails.cart_sub_total) + parseFloat(this.state.subchr_amount)

          var addDelCharge = parseFloat(settingsDet.additional_delivery_charge)

          /* get GST value */
          var gstSubToatl = parseFloat(this.state.cartDetails.cart_sub_total)

          var outlet_tax_option = cookie.load("outlet_tax_option")
          var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
          var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

          if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00") {
            var gstAmount = outlet_tax_surcharge
          } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00") {
            var gstAmount = "0.00"
          } else {
            var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
          }

          //var gstAmount = parseFloat(settingsDet.client_tax_surcharge);
          /* End - Gst value */

          var freeDeliveryAmnt = parseFloat(settingsDet.client_free_delivery_amount)

          if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId && addDelCharge > 0 && freeDeliveryAmnt > 0) {
            var remaingAmnt = parseFloat(freeDeliveryAmnt - gstSubToatl).toFixed(2)
            if (remaingAmnt > 0) {
            } else {
              addDelCharge = 0
            }
          }
          this.setState({ additnl_deliveryAmnt_cal: addDelCharge })

          var service_charge_amount = 0
          if (this.state.online_servicecharge_type === "fixed") {
            var serChrAmount = this.state.online_servicecharge_amount !== "" ? parseFloat(this.state.online_servicecharge_amount) : 0
            service_charge_amount = serChrAmount.toFixed(2)
          } else {
            if (parseFloat(this.state.online_servicecharge_per) > 0) {
              service_charge_amount = helper.getServicecharge(this.state.online_servicecharge_per, originalSubTotalAmt, "format")
            }
          }

          /* get GST value New*/
          var gstSubToatl_delivry = parseFloat(AddDelgrandTotal) + parseFloat(addDelCharge) + parseFloat(service_charge_amount)
          var displayGst = helper.getGstValue(gstAmount, gstSubToatl_delivry, "format")
          /* End - Gst value New*/

          var finResult = AddDelgrandTotal + addDelCharge + parseFloat(displayGst)

          /*this.setState({cartDetails: Object.assign({}, this.state.cartDetails, {cart_grand_total:finResult})}, function () { this.checkDeliveryAmount();
				      this.cal_service_charge('initial'); $('#loadIcon').show(); this.postOrder(2,'Yes'); }.bind(this));*/

          this.setState({ cartDetails: Object.assign({}, this.state.cartDetails, { cart_grand_total: finResult }) }, function () {
            if (this.state.settings.client_omise_enable_secure === "1") {
              if (cookie.load("tempPromoCode") !== "" && typeof cookie.load("tempPromoCode") !== undefined && typeof cookie.load("tempPromoCode") !== "undefined") {
                var tempPromoCode = cookie.load("tempPromoCode")
                this.setState({ appliedPromocode: tempPromoCode, promoApplied: "Yes", promoType: "PROMO" }, function () {
                  if (cookie.load("promo_type") != "add_products") {
                    this.applyCoupon(this.state.cartDetails.cart_sub_total, tempPromoCode)
                  }
                })
              }

              if (cookie.load("tempPoints") !== "" && typeof cookie.load("tempPoints") !== undefined && typeof cookie.load("tempPoints") !== "undefined") {
                var tempPoints = cookie.load("tempPoints")
                /*, */
                this.setState({ promoPoints: tempPoints, promoApplied: "Yes", promoType: "POINTS" }, function () {
                  $("#points").val(tempPoints)
                  this.applyRewardsAuto(tempPoints)
                })
              }
            }
          })

          this.checkDeliveryAmount()
          this.cal_service_charge("initial")

          var callcnterMinAmount = parseInt(settingsDet.callcnter_min_amount)

          if (cookie.load("defaultAvilablityId") === config.ids.deliveryId && gstSubToatl > 0 && gstSubToatl < callcnterMinAmount) {
            helper.showInfo("A minimum order value of " + currency + callcnterMinAmount + ",in order to checkout the cart. current cart value is " + currency + gstSubToatl + ".")
            hashHistory.push("/cart")
            return false
          }

          if (promoreload === false) {
            /* validate alerts  */
            $("#loadIcon").show()
            /*var retun_data = this.postOrder(2,'Yes');*/

            var $_thisPer = this
            setTimeout(function () {
              if ($_thisPer.props.location.query.razerpay != "undefined" && $_thisPer.props.location.query.razerpay != undefined && $_thisPer.props.location.query.razerpay == "success") {
                $_thisPer.setState({ razerpayOrderId: $_thisPer.props.location.query.order_id })
                $("#SwitchingError").modal("toggle")
                $_thisPer.postOrder(7, "No")
              } else if ($_thisPer.props.location.query.callback_omise != "undefined" && $_thisPer.props.location.query.callback_omise != undefined && cookie.load("omise_capture_id") != undefined && cookie.load("omise_capture_id") != "") {
                var captureID = cookie.load("omise_capture_id")
                $_thisPer.retrieve_three_d_charges(captureID)
              } else {
                if (
                  cookie.load("tempPromoCode") === "" ||
                  typeof cookie.load("tempPromoCode") === undefined ||
                  typeof cookie.load("tempPromoCode") === "undefined" ||
                  cookie.load("tempPoints") === "" ||
                  typeof cookie.load("tempPoints") === undefined ||
                  typeof cookie.load("tempPoints") === "undefined"
                ) {
                  $_thisPer.postOrder(2, "Yes")
                }
              }
            }, 600)
          }
          //this.setState({status: 'ok'});
        })
      } else {
        hashHistory.push("/")
      }
    })
  }

  findVoucherProduct() {
    var cartItms = this.state.cartItems
    if (cartItms.length > 0) {
      var voucherExit = 0
      var otherProExist = 0
      cartItms.map(function (item) {
        if (item.cart_item_product_type == "5" && item.cart_voucher_order_item_id == "0" && item.cart_item_unit_price > 0) {
          voucherExit++
          cookie.save("checkoutVoucher", "1")
        } else {
          otherProExist++
          cookie.save("checkoutVoucher", "0")
        }
      })
    }
    if (voucherExit > 0 && otherProExist == "0") {
      cookie.save("checkoutVoucher", "1")
    } else {
      cookie.save("checkoutVoucher", "0")
    }
    if (voucherExit > 0) {
      this.setState({ newVoucher: cookie.load("checkoutVoucher") })
    } else if (otherProExist > 0) {
      this.setState({ newVoucher: cookie.load("checkoutVoucher") })
    }
  }

  /*Reset promotion applied details */
  resetAppliedPromo(type, promoAmount = null, deliveryApplied = null) {
    var $_thisVr = this
    // alert(type);
    if (deliveryApplied !== "Yes") {
      var addDelCharge = parseFloat(this.state.additnl_deliveryAmnt_cal)

      if (type === "Add") {
        var addedgrandTotal = parseFloat(this.state.originalGrandTotal) - parseFloat(promoAmount)
        var AddDelgrandTotal = addedgrandTotal > 0 ? parseFloat(addedgrandTotal) : 0
        var addGstSubToatl = parseFloat(this.state.originalSubTotal) - parseFloat(promoAmount)
        var gstSubToatl = addGstSubToatl > 0 ? parseFloat(addGstSubToatl) : 0
      } else {
        AddDelgrandTotal = parseFloat(this.state.originalGrandTotal)
        gstSubToatl = parseFloat(this.state.originalSubTotal)
        //alert(gstSubToatl + "//" + AddDelgrandTotal);

        var addDelCharge = parseFloat(this.state.settings.additional_delivery_charge)
        var freeDeliveryAmnt = parseFloat(this.state.settings.client_free_delivery_amount)
        if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId && addDelCharge > 0 && freeDeliveryAmnt > 0) {
          var remaingAmnt = parseFloat(freeDeliveryAmnt - gstSubToatl).toFixed(2)
          if (remaingAmnt > 0) {
          } else {
            addDelCharge = 0
          }
        }
      }

      this.setState({ additnl_deliveryAmnt_cal: addDelCharge })

      var service_charge_amount = this.state.cartDetails.service_charge_amount !== "" && this.state.cartDetails.service_charge_amount !== undefined ? parseFloat(this.state.cartDetails.service_charge_amount) : 0

      AddDelgrandTotal = parseFloat(AddDelgrandTotal) + parseFloat(this.state.subchr_amount)

      var outlet_tax_option = cookie.load("outlet_tax_option")
      var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
      var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

      if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00") {
        var gstAmount = outlet_tax_surcharge
      } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00") {
        var gstAmount = "0.00"
      } else {
        var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
      }

      //var gstAmount = parseFloat(this.state.settings.client_tax_surcharge);
      /*var displayGst = helper.getGstValue(gstAmount,gstSubToatl,'format');*/
      var gstSubToatl_delivrytxt = AddDelgrandTotal + addDelCharge + parseFloat(service_charge_amount)
      var displayGst = helper.getGstValue(gstAmount, gstSubToatl_delivrytxt, "format")
      /* End - Gst value */
      var finResult = AddDelgrandTotal + addDelCharge + parseFloat(displayGst)
      /* if less the 0.50 - start */
      if (finResult < parseFloat("0.50") && finResult > 0) {
        finResult = parseFloat("0.50")
        this.setState({ stripeMinPay: "Yes" })
      } else {
        this.setState({ stripeMinPay: "No" })
      }
      /* if less then  0.50 - End */

      this.setState(
        { cartDetails: Object.assign({}, this.state.cartDetails, { cart_sub_total: gstSubToatl, cart_grand_total: finResult }) },
        function () {
          this.checkDeliveryAmount(gstSubToatl, "No")
          setTimeout(function () {
            $_thisVr.cal_service_charge("change")
          }, 100)
          //this.postOrder(2,'Yes');
        }.bind(this)
      )
    } else {
      this.checkDeliveryAmount(this.state.originalSubTotal, "Yes")
      setTimeout(function () {
        $_thisVr.cal_service_charge("change")
      }, 100)
    }

    /*this.cal_service_charge('change');*/
  }

  /* this function used to validate customer information */
  validateCustomer() {}

  /* this function used to validate customer information */
  validateCart() {}

  /* this function used to validate order details */
  validateOrderDetails() {}

  otp_verification() {
    var otpObject = {}
    var otp_value = $("#redeem_points_otp").val()

    if (otp_value != "") {
      document.getElementById("PromoLoading").style.display = "block"

      var qs = require("qs")
      otpObject = { app_id: APPID, customer_otp_val: otp_value, customer_email_id: cookie.load("UserEmail"), verify_type: "redeempoints" }

      axios.post(APIURL + "customer/customer_otp_verification", qs.stringify(otpObject)).then((response) => {
        document.getElementById("PromoLoading").style.display = "none"
        if (response.data.status === "ok") {
          this.otp_redeem_points()
          $("#redeem_points_otp").val("")
        } else {
          helper.showInfo(response.data.message)
        }
      })
    } else {
      $("#redeem_points_otp").val("")
      helper.showInfo("Enter valid OTP.")
      return false
    }
  }

  otp_cancel_option() {
    document.getElementById("redeem_points_option").style.display = "block"
    document.getElementById("redeem_point_otp_div").style.display = "none"
    document.getElementById("resend_otp_button").style.display = "block"
    $("#redeem_points_otp").val("")
  }

  handleResendotp() {
    var otp_limit = this.state.customer_otp_limit + 1
    var qs = require("qs")
    var postObject = { app_id: APPID, customer_email: cookie.load("UserEmail"), customer_mobile: cookie.load("UserMobile"), customer_first_name: cookie.load("UserFname"), customer_last_name: cookie.load("UserLname"), verify_type: "redeempoints" }

    if (otp_limit > 1) {
      //helper.showInfo("OTP limit has been reached.");
      document.getElementById("resend_otp_button").style.display = "none"
    } else {
      document.getElementById("PromoLoading").style.display = "block"

      axios.post(APIURL + "customer/resend_otp", qs.stringify(postObject)).then((response) => {
        document.getElementById("PromoLoading").style.display = "none"
        if (response.data.status === "ok") {
          this.setState({ customer_otp_limit: otp_limit })
          helper.showInfo(response.data.message)
          document.getElementById("resend_otp_button").style.display = "none"
        } else {
          helper.showInfo(response.data.message)
        }
      })
    }
  }

  otp_redeem_points() {
    document.getElementById("PromoLoading").style.display = "block"
    var cartSubToatl = parseFloat(this.state.originalSubTotal)
    var pointsVal = parseFloat($("#points").val())
    var pointsObject = {}

    pointsObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      pin: this.state.advocadopin,
      redeem_point: pointsVal,
      cart_amount: parseFloat(cartSubToatl),
    }

    axios.post(APIURL + "loyalty/apply_loyalityv1", qs.stringify(pointsObject)).then((res) => {
      document.getElementById("PromoLoading").style.display = "none"
      if (res.data.status === "success") {
        this.resetAppliedPromo("Add", parseFloat(res.data.result_set.points_amount), "No")
        this.setState({
          promoApplied: "Yes",
          promoAmount: parseFloat(res.data.result_set.points_amount),
          promoType: "POINTS",
          promoPoints: parseFloat(res.data.result_set.points_used),
        })

        this.postOrder(2, "Yes")

        document.getElementById("redeem_points_option").style.display = "block"
        document.getElementById("redeem_point_otp_div").style.display = "none"

        helper.showInfo("OTP is verified successfully and rewards points are added your order.")
      } else {
        this.resetPromoState()
        helper.showInfo(res.data.message)
      }
    })
  }

  /*apply rewards function*/
  applyRewards() {
    //helper.showInfo('Invalid Rewards');
    var pointsObject = {}
    var postOTPObject = {}
    var pointsVal = parseFloat($("#points").val())

    if (pointsVal > 0) {
      document.getElementById("PromoLoading").style.display = "block"

      var company_otp_verification = cookie.load("client_otp_verification_enable")
      var company_otp_via = cookie.load("client_otp_verification_via")

      var qs = require("qs")

      if (company_otp_verification === "1") {
        postOTPObject = {
          app_id: APPID,
          customer_first_name: cookie.load("UserFname"),
          customer_last_name: cookie.load("UserLname"),
          customer_email: cookie.load("UserEmail"),
          customer_phone: cookie.load("UserMobile"),
          customer_otp_type: company_otp_via,
          verify_type: "redeempoints",
        }

        axios.post(APIURL + "customer/send_customer_otp", qs.stringify(postOTPObject)).then((response) => {
          document.getElementById("PromoLoading").style.display = "none"
          if (response.data.status === "ok") {
            document.getElementById("redeem_points_option").style.display = "none"
            document.getElementById("redeem_point_otp_div").style.display = "block"
            $("#redeem_points_otp").focus()
            if (company_otp_via === "1") {
              helper.showInfo("OTP has been sent to your email id.")
            } else {
              helper.showInfo("OTP has been sent to your mobile number.")
            }
          } else {
            helper.showInfo(response.data.message)
          }
          return false
        })
      } else {
        let cartSubToatl = this.getOriginalGrandTotal(parseFloat(this.state.originalSubTotal))
        this.setState({ applyRewardsOriginalGrandTotal: cartSubToatl })

        pointsObject = {
          /* cart details */
          app_id: APPID,
          reference_id: cookie.load("UserId"),
          pin: this.state.advocadopin,
          redeem_point: pointsVal,
          cart_amount: parseFloat(cartSubToatl),
        }
        //this.setState({status:"Loading"});
        axios.post(APIURL + "loyalty/apply_loyalityv1", qs.stringify(pointsObject)).then((res) => {
          document.getElementById("PromoLoading").style.display = "none"
          if (res.data.status === "success") {
            console.log("cartSubToatl ==> ", parseFloat(cartSubToatl) - parseFloat(res.data.result_set.points_amount))
            // helper.showInfo(res.data.message)
            this.setState(
              {
                promoApplied: "Yes",
                promoAmount: parseFloat(res.data.result_set.points_amount),
                promoType: "POINTS",
                promoPoints: parseFloat(res.data.result_set.points_used),
                finalApplyRewardsOriginalGrandTotal: parseFloat(cartSubToatl) - parseFloat(res.data.result_set.points_amount),
              },
              function () {
                cookie.save("tempPoints", pointsVal)
                this.resetAppliedPromo("Add", parseFloat(res.data.result_set.points_amount), "No")
              }
            )

            var retun_data = this.postOrder(2, "Yes") /* Todo : 1 */
          } else {
            this.resetPromoState()
            helper.showInfo(res.data.message)
          }
        })
      }
    } else {
      $("#points").val("")
      helper.showInfo(alertMsg.pointsRequired)
      return false
    }
  }

  /*apply rewards function*/
  applyRewardsAuto(pointsVal) {
    //helper.showInfo('Invalid Rewards');
    var pointsObject = {}
    var postOTPObject = {}

    if (pointsVal > 0) {
      if ($("#PromoLoading").length > 0) {
        document.getElementById("PromoLoading").style.display = "block"
      }

      var company_otp_verification = cookie.load("client_otp_verification_enable")
      var company_otp_via = cookie.load("client_otp_verification_via")

      var qs = require("qs")

      let cartSubToatl = this.getOriginalGrandTotal(parseFloat(this.state.originalSubTotal))
      this.setState({ applyRewardsOriginalGrandTotal: cartSubToatl })

      pointsObject = {
        /* cart details */
        app_id: APPID,
        reference_id: cookie.load("UserId"),
        pin: this.state.advocadopin,
        redeem_point: pointsVal,
        cart_amount: parseFloat(cartSubToatl),
      }
      //this.setState({status:"Loading"});
      axios.post(APIURL + "loyalty/apply_loyalityv1", qs.stringify(pointsObject)).then((res) => {
        if ($("#PromoLoading").length > 0) {
          document.getElementById("PromoLoading").style.display = "none"
        }
        if (res.data.status === "success") {
          console.log("cartSubToatl ==> ", parseFloat(cartSubToatl) - parseFloat(res.data.result_set.points_amount))
          // helper.showInfo(res.data.message)

          this.setState(
            {
              promoApplied: "Yes",
              promoAmount: parseFloat(res.data.result_set.points_amount),
              promoType: "POINTS",
              promoPoints: parseFloat(res.data.result_set.points_used),
              finalApplyRewardsOriginalGrandTotal: parseFloat(cartSubToatl) - parseFloat(res.data.result_set.points_amount),
            },
            function () {
              this.resetAppliedPromo("Add", parseFloat(res.data.result_set.points_amount), "No")
            }
          )
        } else {
          this.resetPromoState()
          helper.showInfo(res.data.message)
        }
      })
    } else {
      $("#points").val("")
      helper.showInfo(alertMsg.pointsRequired)
      return false
    }
  }

  /* Advocado registration */

  advocadoregister() {
    var advocadoObj = {}

    if (this.state.advocadopin !== undefined || this.state.advocadopin !== "") {
      document.getElementById("PromoLoading").style.display = "block"
      advocadoObj = {
        app_id: APPID,
        customer_id: cookie.load("UserId"),
        pin: this.state.advocadopin,
      }

      //this.setState({status:"Loading"});
      axios.post(APIURL + "customer/advocado_register", qs.stringify(advocadoObj)).then((res) => {
        document.getElementById("PromoLoading").style.display = "none"
        if (res.data.status === "success") {
          $("#advocadopopup").modal("toggle")
          setTimeout(function () {
            location.reload()
          }, 6000)
          helper.showInfo(res.data.message)
        } else {
          $("#advocadopopup").modal("toggle")
          setTimeout(function () {
            location.reload()
          }, 6000)
          helper.showInfo(res.data.message)
        }
      })
    } else {
    }
  }

  /* Advocado registration */

  advocadoforgot() {
    var advocadoObj = {}

    if (this.state.advocadopin !== undefined || this.state.advocadopin !== "") {
      document.getElementById("PromoLoading").style.display = "block"
      advocadoObj = {
        app_id: APPID,
        customer_id: cookie.load("UserId"),
      }

      //this.setState({status:"Loading"});
      axios.post(APIURL + "customer/advocado_forgot_pin", qs.stringify(advocadoObj)).then((res) => {
        document.getElementById("PromoLoading").style.display = "none"
        if (res.data.status === "success") {
          helper.showInfo(res.data.message)
        } else {
          helper.showInfo(res.data.message)
        }
      })
    } else {
    }
  }

  /*apply coupon new function*/
  applyCoupon(SubTotal, AutoPromoCode) {
    //return false;
    var promoObject = {}
    if (AutoPromoCode !== "" && typeof AutoPromoCode !== undefined && typeof AutoPromoCode !== "undefined") {
      var promocode = AutoPromoCode
    } else {
      var promocode = $("#promocode").val()
    }

    if (promocode === "") {
      helper.showInfo(alertMsg.promoRequired)
      return false
    }

    if ($("#PromoLoading").length > 0) {
      document.getElementById("PromoLoading").style.display = "block"
    }

    var cartSubToatl = parseFloat(this.state.originalSubTotal)
    var cartItemsCount = this.state.cartDetails.cart_total_items
    const deliveryAmnt = parseFloat(helper.getDeliveryCharge(this.state.promoApplied, this.state.promoAppliedDelivery, this.state.originalDeliveryAmnt, this.state.isFreeDelivery))
    const AdditionalDeliveryAmnt = parseFloat(this.state.additnl_deliveryAmnt_cal)

    promoObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(),
      availability_id: cookie.load("defaultAvilablityId"),
      delivery_amount: deliveryAmnt,
      additional_delivery_amount: AdditionalDeliveryAmnt,
      totalDeliveryCharge: deliveryAmnt + AdditionalDeliveryAmnt,
    }

    axios.post(APIURL + "Promotion_api_v3/apply_promotion_v2", qs.stringify(promoObject)).then((res) => {
      if ($("#PromoLoading").length > 0) {
        document.getElementById("PromoLoading").style.display = "none"
      }

      if (res.data.status === "success") {
        var pointDet = res.data.result_set
        if (pointDet.promotion_delivery_charge_applied === "Yes" && this.state.cartDetails.cart_availability_id !== config.ids.deliveryId) {
          this.resetPromoState()
          helper.showInfo(alertMsg.promoCodeDeliveryAlert)
          return false
        }

        let promo_object = {
          promoApplied: "Yes",
          promoAmount: parseFloat(pointDet.promotion_amount),
          promocode: pointDet.promotion_code,
          promoAppliedDelivery: pointDet.promotion_delivery_charge_applied,
          promotionCategory: pointDet.promotionCategory,
          promotionId: pointDet.promotionId,
          previousPromotionAmount: pointDet.promotion_amount,
          promoQty: cartItemsCount,
          promoType: "PROMO",
          promoPoints: 0,
          promo_with_delivery: pointDet.is_include_delivery_discount,
          original_promotion_amount: parseFloat(typeof pointDet.original_promotion_amount !== "undefined" && pointDet.original_promotion_amount > 0 ? pointDet.original_promotion_amount : 0),
          delivery_promotion_amount: parseFloat(typeof pointDet.delivery_promotion_amount !== "undefined" && pointDet.delivery_promotion_amount > 0 ? pointDet.delivery_promotion_amount : 0),
        }
        this.setState(promo_object, function () {
          cookie.save("tempPromoCode", pointDet.promotion_code, { path: "/" })
          this.resetAppliedPromo("Add", parseFloat(pointDet.promotion_amount), pointDet.promotion_delivery_charge_applied)
        })

        if (typeof pointDet.prmo_type !== "undefined" && pointDet.prmo_type === "add_products") {
          this.setState({ promo_type: "add_products" })
          cookie.save("promo_type", "add_products")
          cookie.save("promo_object", promo_object)
          cookie.save("is_custom_redirect", "yes")
          cookie.save("promotion_code", pointDet.promotion_code)
          hashHistory.push("/")
          hashHistory.push("/checkout")
        } else {
          if (AutoPromoCode === "") {
            var retun_data = this.postOrder(2, "Yes")
          }
        }
      } else {
        this.resetPromoState()
        helper.showInfo(res.data.message)
      }
    })
  }

  /*apply coupon  function*/
  applyCoupon_old(SubTotal) {
    // helper.showInfo('Invalid Promocode');

    //alert(SubTotal);

    var promoObject = {}
    var promocode = $("#promocode").val()

    if (promocode === "") {
      //alert("The Promo code field is Required.")
      helper.showInfo(alertMsg.promoRequired)
      return false
    }

    document.getElementById("PromoLoading").style.display = "block"
    /* get cat category ids */
    //get_productid_cart()
    //{
    var product_cartid = ""
    var cartItems = this.state.cartItems
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        ///alert(cartItems[p].cart_item_product_id);
        if (product_cartid !== "" && cartItems[p].cart_item_product_id !== "") {
          product_cartid += ";"
        }
        product_cartid += cartItems[p].cart_item_product_id + "|" + cartItems[p].cart_item_total_price
      }
    }

    var cartSubToatl = parseFloat(this.state.originalSubTotal)
    var cartItemsCount = this.state.cartDetails.cart_total_items
    promoObject = {
      /* cart details */
      app_id: APPID,
      reference_id: cookie.load("UserId"),
      promo_code: promocode,
      cart_amount: parseFloat(cartSubToatl),
      cart_quantity: cartItemsCount,
      category_id: this.getPromoCategory(),
    }
    //this.setState({status:"Loading"});
    axios.post(APIURL + "promotion_api_v1/apply_promotion", qs.stringify(promoObject)).then((res) => {
      document.getElementById("PromoLoading").style.display = "none"

      if (res.data.status === "success") {
        if (res.data.result_set.promotion_delivery_charge_applied === "Yes" && this.state.cartDetails.cart_availability_id !== config.ids.deliveryId) {
          this.resetPromoState()
          helper.showInfo(alertMsg.promoCodeDeliveryAlert)
          return false
        }

        this.resetAppliedPromo("Add", parseFloat(res.data.result_set.promotion_amount), res.data.result_set.promotion_delivery_charge_applied)
        this.setState({
          promoApplied: "Yes",
          promoAmount: parseFloat(res.data.result_set.promotion_amount),
          promocode: res.data.result_set.promotion_code,
          promoAppliedDelivery: res.data.result_set.promotion_delivery_charge_applied,
          promotionCategory: res.data.result_set.promotionCategory,
          promotionId: res.data.result_set.promotionId,
          previousPromotionAmount: res.data.result_set.promotion_amount,
          promoQty: cartItemsCount,
          promoType: "PROMO",
          promoPoints: 0,
        })
        var retun_data = this.postOrder(2, "Yes")
      } else {
        this.resetPromoState()
        helper.showInfo(res.data.message)
      }
    })
  }

  /*  get promo category details  */
  getPromoCategory() {
    var product_cartid = ""
    var cartItems = this.state.cartItems
    var cartCount = cartItems.length
    if (cartCount > 0) {
      for (var p = 0; p < cartCount; p++) {
        if (product_cartid !== "" && cartItems[p].cart_item_product_id !== "") {
          product_cartid += ";"
        }
        product_cartid += cartItems[p].cart_item_product_id + "|" + cartItems[p].cart_item_total_price + "|" + cartItems[p].cart_item_qty
      }
    }

    return product_cartid
  }

  /*  reset prmo state values */
  resetPromoState() {
    this.setState({
      promoApplied: "No",
      promoAmount: 0,
      promocode: "",
      promoAppliedDelivery: "No",
      promotionCategory: "",
      promotionId: "",
      promoQty: 0,
      promoType: "",
      promoPoints: 0,
      advocadopin: "",
      appliedPromocode: "",
    })

    cookie.remove("promotion_code")
    cookie.remove("tempPromoCode")
    cookie.remove("tempPoints")
    $("#promocode").val("")
    $("#points").val("")
    this.resetAppliedPromo("Remove", 0, "No")
    this.resetPromoProducts()
  }

  resetPromoProducts() {
    if (this.state.promo_type === "add_products" || cookie.load("promo_type") === "add_products") {
      var qs = require("qs")
      let otpObject = { app_id: APPID, customer_id: cookie.load("UserId"), product_promotion_type: "add_products" }

      axios.post(APIURLPRO + "cartv3/delete_promo_type", qs.stringify(otpObject)).then((response) => {
        this.setState({ promo_type: "" })
        cookie.remove("promo_type")
        cookie.remove("promo_object")
        cookie.remove("is_custom_redirect")
        hashHistory.push("/")
        hashHistory.push("/checkout")
      })
    }
  }

  /* for apply rewards on grand total */
  getOriginalGrandTotal(returnVal) {
    let cartGrandTotal = returnVal
    if (rewardsApply === 1 && includes(rewardsApplyList, APPID)) {
      if (this.state.newVoucher === "1") {
        cartGrandTotal = parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2)
      } else {
        cartGrandTotal = (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2)
      }
    }

    return cartGrandTotal
  }

  isApplyRewardOnGrandTotal() {
    return rewardsApply === 1 && includes(rewardsApplyList, APPID) && this.state.promoApplied === "Yes" && this.state.promoType === "POINTS" ? true : false
  }

  /* this function used to load product items */
  loadProductsDetails() {
    var subTotalVal = helper.getsubTotal(this.state.cartDetails.cart_sub_total, this.state.originalSubTotal, this.state.promoApplied, this.state.reedemptionApplied)

    var ServiceChargeText = this.state.onlineservice_displaylabel !== "" ? this.state.onlineservice_displaylabel : "Service Charge"

    if (this.state.newVoucher === "1") {
      var cartGrandTotal = config.ids.currency + parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2)
    } else {
      cartGrandTotal = config.ids.currency + (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2)
    }

    /*  apply grand total on rewards */
    if (this.isApplyRewardOnGrandTotal()) {
      subTotalVal = parseFloat(this.state.originalSubTotal)
      cartGrandTotal = (parseFloat(this.state.applyRewardsOriginalGrandTotal) - parseFloat(this.state.promoAmount)).toFixed(2)
      //this.setState({finalApplyRewardsOriginalGrandTotal : cartGrandTotal });
    }

    return (
      <table>
        <tbody>
          {this.loadProductItems()}

          <tr className="sub-total-tr">
            <td className="sub-total">Sub Total</td>
            <td className="sub-total text-right">{parseFloat(this.state.originalSubTotal).toFixed(2)}</td>
          </tr>

          {parseFloat(this.state.cartDetails.service_charge_amount) > 0 && this.state.newVoucher != "1" && (
            <tr className="sub-total-tr">
              {this.state.online_servicecharge_type === "fixed" ? (
                <td className="sub-total">{ServiceChargeText}</td>
              ) : (
                <td className="sub-total">
                  {ServiceChargeText} ({parseFloat(this.state.online_servicecharge_per)}%)
                </td>
              )}
              <td className="sub-total text-right">{this.state.cartDetails.service_charge_amount}</td>
            </tr>
          )}

          {this.loadDeliveryContent(this.state.cartDetails.cart_availability_id)}
          {this.loadAddDeliveryContent(this.state.cartDetails.cart_availability_id)}
          {this.surchargeAmount()}
          {this.discountApplied()}
          {this.loadTaxAmount(subTotalVal)}

          <tr>
            <td className="total">Total {this.stripeMinPayText()}</td>
            <td className="total text-right">{cartGrandTotal}</td>
          </tr>
          {this.showGstInclusive(subTotalVal)}
        </tbody>
      </table>
    )
  }

  /* stripe MIn pay text */
  stripeMinPayText() {
    if (this.state.stripeMinPay === "Yes") {
      return <span className="font_snall">({alertMsg.stripeMinPaymentText})</span>
    }
  }

  /* discpunt applied  text */

  discountApplied() {
    if (this.state.promoApplied === "Yes" && this.state.promoAppliedDelivery === "No" && this.state.promo_type !== "add_products") {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">
            Discount Applied
            {this.state.promo_with_delivery === "Yes" && this.showDeliveryEnabledDiscount()}
          </td>
          <td className="sub-total text-right"> - {parseFloat(this.state.promoAmount).toFixed(2)}</td>
        </tr>
      )
    }
  }

  showDeliveryEnabledDiscount() {
    if (this.state.original_promotion_amount > 0 && this.state.delivery_promotion_amount > 0) {
      return (
        <p>
          (Delivery -${this.state.delivery_promotion_amount} Product -${this.state.original_promotion_amount})
        </p>
      )
    } else if (this.state.original_promotion_amount > 0) {
      return <p>(Product -${this.state.original_promotion_amount})</p>
    } else if (this.state.delivery_promotion_amount > 0) {
      return <p>(Delivery -${this.state.delivery_promotion_amount})</p>
    }
  }

  /* this function used to load delivery content */
  loadDeliveryContent(availblity) {
    var deliveryAmnt = helper.getDeliveryCharge(this.state.promoApplied, this.state.promoAppliedDelivery, this.state.originalDeliveryAmnt, this.state.isFreeDelivery)
    var voucherNew = this.state.newVoucher
    if (availblity === config.ids.deliveryId && voucherNew != "1" && deliveryAmnt > 0) {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">Delivery</td>
          <td className="sub-total text-right">{parseFloat(deliveryAmnt).toFixed(2)}</td>
        </tr>
      )
    }
  }

  /* this function used to load delivery content */
  loadTaxAmount(subTotal) {
    //var gstAmount = parseFloat(this.state.settings.client_tax_surcharge);

    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1 && outlet_tax_surcharge > "0.00") {
      var gstAmount = outlet_tax_surcharge
    } else if (outlet_tax_option == 1 && outlet_tax_surcharge_inclusive > "0.00") {
      var gstAmount = "0.00"
    } else {
      var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
    }

    var subTotal_delvry = subTotal
    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      var delAmount = parseFloat(this.state.additnl_deliveryAmnt_cal)
      var deliveryAmnt = helper.getDeliveryCharge(this.state.promoApplied, this.state.promoAppliedDelivery, this.state.originalDeliveryAmnt, this.state.isFreeDelivery)
      var subTotal_delvry = parseFloat(subTotal) + parseFloat(delAmount) + parseFloat(deliveryAmnt)
    }

    var service_charge_amount = this.state.cartDetails.service_charge_amount !== "" && this.state.cartDetails.service_charge_amount !== undefined ? parseFloat(this.state.cartDetails.service_charge_amount) : 0

    subTotal_delvry = parseFloat(subTotal_delvry) + parseFloat(this.state.subchr_amount) + parseFloat(service_charge_amount)

    var displayGst = helper.getGstValue(gstAmount, subTotal_delvry, "format")
    var voucherNew = this.state.newVoucher
    if (gstAmount > 0 && voucherNew != "1") {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">GST({gstAmount}%)</td>
          <td className="sub-total text-right">{displayGst}</td>
        </tr>
      )
    }
  }

  /* load gstInclusive  amount */

  showGstInclusive(subTotal) {
    //var gstAmount = parseFloat(this.state.settings.client_tax_surcharge);
    //var gstInclusive = parseFloat(this.state.settings.client_tax_surcharge_inclusive);

    var outlet_tax_option = cookie.load("outlet_tax_option")
    var outlet_tax_surcharge = cookie.load("outlet_tax_surcharge")
    var outlet_tax_surcharge_inclusive = cookie.load("outlet_tax_surcharge_inclusive")

    if (outlet_tax_option == 1) {
      if (outlet_tax_surcharge_inclusive > "0.00") {
        var gstAmount = parseFloat(outlet_tax_surcharge)
        var gstInclusive = parseFloat(outlet_tax_surcharge_inclusive)
      } else {
        var gstAmount = parseFloat(outlet_tax_surcharge)
        var gstInclusive = "0.00"
      }
    } else {
      var gstAmount = parseFloat(this.state.settings.client_tax_surcharge)
      var gstInclusive = parseFloat(this.state.settings.client_tax_surcharge_inclusive)
    }

    var subTotal_delvry = subTotal

    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId) {
      var delAmount = parseFloat(this.state.additnl_deliveryAmnt_cal)
      var deliveryAmnt = helper.getDeliveryCharge(this.state.promoApplied, this.state.promoAppliedDelivery, this.state.originalDeliveryAmnt, this.state.isFreeDelivery)
      var subTotal_delvry = parseFloat(subTotal) + parseFloat(delAmount) + parseFloat(deliveryAmnt)
    }

    var service_charge_amount = this.state.cartDetails.service_charge_amount !== "" && this.state.cartDetails.service_charge_amount !== undefined ? parseFloat(this.state.cartDetails.service_charge_amount) : 0

    subTotal_delvry = parseFloat(subTotal_delvry) + parseFloat(this.state.subchr_amount) + service_charge_amount

    var displayInclusiveGst = helper.getInclusiveGst(gstInclusive, subTotal_delvry, "format")

    if (gstAmount === 0 && displayInclusiveGst.text != "undefined") {
      cookie.save("gstInclusive", displayInclusiveGst.text)
      cookie.save("gstInclusiveValue", displayInclusiveGst.value)
      cookie.save("gstInclusivePercent", displayInclusiveGst.percentage)
      return (
        <tr>
          <td className="text-right" colSpan={2}>
            {displayInclusiveGst.text}
          </td>
        </tr>
      )
    }
  }

  /* this function used to load surcharge amount */
  surchargeAmount() {
    var surchrAmount = parseFloat(this.state.subchr_amount)
    var voucherNew = this.state.newVoucher
    if (surchrAmount > 0 && voucherNew != "1") {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">Surcharge</td>
          <td className="sub-total text-right">{surchrAmount.toFixed(2)}</td>
        </tr>
      )
    }
  }

  //loadAddDeliveryContent{

  /* this function used to load delivery content */
  loadAddDeliveryContent(availblity) {
    /*var delAmount = parseFloat(this.state.settings.additional_delivery_charge);*/
    var delAmount = parseFloat(this.state.additnl_deliveryAmnt_cal)
    var voucherNew = this.state.newVoucher
    if (availblity === config.ids.deliveryId && delAmount > 0 && voucherNew != "1") {
      return (
        <tr className="sub-total-tr">
          <td className="sub-total">
            Additional Surcharge
            {APPID === "A2924058-9DEC-4A6B-A92C-F762D2C1A7E3" ? <span className="font_snall"> (This is CBD Zone Surcharge) </span> : <span className="font_snall"> (This is applicable to areas that are 5km and further from our address)</span>}
          </td>
          <td className="sub-total text-right">{parseFloat(delAmount).toFixed(2)}</td>
        </tr>
      )
    }
  }

  /*load product details */
  loadProductItems() {
    return this.state.cartItems.map((item, index) => (
      <tr key={index}>
        <td className="main-item">
          {item.cart_item_qty} X {strp.stripslashes(item.cart_item_product_name)}
          {this.loadModifierItems(item.cart_item_type, item.modifiers, item.set_menu_component)}
        </td>
        <td className="text-right">{item.cart_item_total_price}</td>
        {this.showProAvblErrorMgs(item)}
        {this.showProStockErrorMgs(item)}
      </tr>
    ))
  }

  checkOutFlag(cartItm) {
    var checkFlag = 0
    this.state.cartItems.map((cartItm, index) => {
      if (cartItm.pro_current_datetime_avl === "no" || cartItm.pro_fullfill_datetime_avl === "no" || cartItm.pro_current_stock_avl === "no" || cartItm.pro_fullfill_stock_avl === "no") {
        checkFlag++
      }
    })
    return checkFlag === 0 ? "block" : "none"
  }

  showProAvblErrorMgs(cartItms) {
    // console.log(cartItms); return false;
    var errorMgs = ""
    var availability = cookie.load("defaultAvilablityId")
    var showtext = "delivery/pickup"
    if (availability === config.ids.deliveryId) {
      showtext = "delivery"
    } else if (availability === config.ids.pickupId) {
      showtext = "pickup"
    }
    if (cartItms.pro_current_datetime_avl === "no") {
      errorMgs = "This product is not available for order today. Please refer to the product description."
    } else if (cartItms.pro_fullfill_datetime_avl === "no") {
      errorMgs = "This product is not available for " + showtext + " on the selected date and time. Please refer to the product description."
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>
    }
  }

  showProStockErrorMgs(cartItms) {
    var errorMgs = ""
    var availability = cookie.load("defaultAvilablityId")
    var showtext = "delivery/pickup"
    if (availability === config.ids.deliveryId) {
      showtext = "delivery"
    } else if (availability === config.ids.pickupId) {
      showtext = "pickup"
    }
    if (cartItms.pro_current_stock_avl === "no") {
      errorMgs = "The selected quantity is not available for order."
    } else if (cartItms.pro_fullfill_stock_avl === "no") {
      errorMgs = "The selected quantity is not available for " + showtext + " on the selected date and time."
    }

    if (errorMgs !== "") {
      return <div className="pro-avbl-errormsg">{errorMgs}</div>
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length
    var comboLen = combo.length
    var html = "<ul className='table_list'>"

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"]
        html += "<li className='sub-item'>" + modName + " : " + modval + "</li> "
      }
      html += "</ul>"
      var reactElement = htmlToReactParserTo.parse(html)
      return reactElement
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"]
        var comboVal = this.showComboProducts(combo[i]["product_details"])
        /* html += "<li className='sub-item'><b>"+comboName+" :</b>"+comboVal+" "+this.showComboModifiers(combo[i]['product_details'][0]['modifiers'])+"</li> "; */
        html += "<li className='sub-item'><b>" + comboName + " :</b> " + comboVal + " " + this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) + "</li> "
      }
      html += "</ul>"
      var reactElement = htmlToReactParserTo.parse(html)
      return reactElement
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length
    var html = " "
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["cart_menu_component_product_name"]
        comboPro = comboPro !== "" ? strp.stripslashes(comboPro) : comboPro
        var comboQty = combos[r]["cart_menu_component_product_qty"]
        var comboPrice = combos[r]["cart_menu_component_product_price"]
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : ""
        html += "<li className='sub-item'>" + comboQty + " X " + comboPro + newPrice + " </li> "
      }
      return html
    }
    return ""
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    //console.log(modifiers);
    var lenMod = modifiers.length
    var html = "<ul className='table_list'>"
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"]
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"]
        var modValPrice = modifiers[i]["modifiers_values"][0]["cart_modifier_price"]
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : ""
        html += "<li className='sub-item'>" + modName + "  : " + modval + newModValPrice + "</li> "
      }
      html += "</ul>"
      // var reactElement = htmlToReactParserTo.parse(html );
      return html
    }

    return ""
  }

  processRazerpay() {
    var self = this
    var razerpay_api_key = this.state.settings.razerpay_api_key
    var razerpay_key_secret = this.state.settings.razerpay_key_secret
    var razerpay_secret_key = this.state.settings.razerpay_secret_key
    var postObject = {}
    var calc_grand_total = this.getApplyRewardsPaymentTotal(this.state.newVoucher != "1" ? parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount) : parseFloat(this.state.cartDetails.cart_sub_total))
    /*Calculate service charge End*/
    //calc_grand_total = parseInt(calc_grand_total * 100);

    var current = this

    var redirectUrl = window.location.protocol + "//" + window.location.hostname + "/#/checkout"
    postObject = {
      app_id: APPID,
      razerpay_api_key: razerpay_api_key,
      razerpay_key_secret: razerpay_key_secret,
      razerpay_secret_key: razerpay_secret_key,
      customer_id: cookie.load("UserId"),
      outlet_id: cookie.load("orderOutletId"),
      amount: calc_grand_total,
      email: cookie.load("UserEmail"),
      mobile: cookie.load("UserMobile"),
      firstname: cookie.load("UserFname"),
      lastname: cookie.load("UserLname"),
      address: cookie.load("orderDeliveryAddress"),
      return_url: APIURL + "paymentv1/razerpay_success",
      cancel_url: APIURL + "paymentv1/razerpay_canceled",
      redirect_url: redirectUrl,
    }

    axios
      .post(APIURL + "paymentv1/razorpayOrder?" + Math.random().toString(36).substring(10), qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === true) {
          console.info(res.data)
          $("#molpay_btn").html(
            '<button type="button" id="myPay" class="btn btn-primary btn-lg" data-toggle="molpayseamless" data-mpsmerchantid="' +
              res.data.mpsmerchantid +
              '" data-mpschannel="' +
              res.data.mpschannel +
              '" data-mpsamount="' +
              res.data.mpsamount +
              '" data-mpsorderid="' +
              res.data.mpsorderid +
              '" data-mpsbill_name="' +
              res.data.mpsbill_name +
              '" data-mpscurrency="' +
              res.data.mpscurrency +
              '" data-mpsvcode="' +
              res.data.mpsvcode +
              '">Pay by Maybank2u</button>'
          )

          var var_mpsbill_name = res.data.mpsbill_name
          var var_mpsbill_email = res.data.mpsbill_email
          if ($('[data-toggle="molpayseamless"]')) {
            $('[data-toggle="molpayseamless"]').attr("data-mpsbill_name", var_mpsbill_name)
          } else {
            $("[data-toggle=molpayseamless]").attr("data-mpsbill_name", var_mpsbill_name)
          } //End of if()
          if ($('[data-toggle="molpayseamless"]')) {
            $('[data-toggle="molpayseamless"]').attr("data-mpsbill_email", var_mpsbill_email)
          } else {
            $("[data-toggle=molpayseamless]").attr("data-mpsbill_email", var_mpsbill_email)
          } //End of if()
          if ($('[data-toggle="molpayseamless"]')) {
            $('[data-toggle="molpayseamless"]').attr("data-mpsreturnurl", res.data.mpsreturnurl)
          } else {
            $("[data-toggle=molpayseamless]").attr("data-mpsreturnurl", res.data.mpsreturnurl)
          } //End of if()
          $("#loading-popup").modal("toggle")
          $(".loading-popup-img").hide()
          $("#molpay_btn").hide()
          $("#myPay").trigger("click")

          //self.setState({razerpayOrderId:res.data.merchant_order_id});
          //self.renderIframe(res);
        } else {
          self.setState({ isPaymentProcessing: response.message })
        }
      })
      .catch((error) => {})
  }

  /* this function used to  validate payment method.. */
  payNow(method = null) {
    fbPixelCustomEvent("EnteredPaymentInfo", {})
    $(".checkout-note").hide()
    if (method === "PROMOTION") {
      this.setState({ paymentStatus: "PROMOTION" }) /* used process screen html show */
      this.postOrder(4)
      this.setState({ defaultClass: "chk_popup_bg" })
      $("#SwitchingError").modal("toggle")
    } else {
      if (document.getElementById("cash") !== null && document.getElementById("cash").checked) {
        console.log("cash")
        this.setState({ paymentStatus: "CASH" }) /* used process screen html show */
        this.postOrder(1)
        this.setState({ defaultClass: "chk_popup_bg" })
        $("#SwitchingError").modal("toggle")
      } else if (document.getElementById("offline") !== null && document.getElementById("offline").checked) {
        this.setState({ paymentStatus: "OFFLINE" }) /* used process screen html show */
        this.postOrder(10)
        this.setState({ defaultClass: "chk_popup_bg" })
        $("#SwitchingError").modal("toggle")
      } else if (document.getElementById("stripe") !== null && document.getElementById("stripe").checked) {
        this.setState({ paymentStatus: "ONLINE" }) /* used process screen html show */
        cookie.save("orderPaymentMode", "STRIPE")
        //indow.location="/pay-now";
        hashHistory.push("/pay-now")
      } else if (document.getElementById("razerpay") !== null && document.getElementById("razerpay").checked) {
        $("#loading-popup").modal("toggle")
        $(".loading-popup-img").show()
        $(".checkout-note").show()
        this.setState({ isPaymentProcessing: "processing" })
        this.processRazerpay()
      } else if (document.getElementById("omise") !== null && document.getElementById("omise").checked) {
        $("#omisepopup").modal({ backdrop: "static", keyboard: false })
      } else if (document.getElementById("omise-paynow") !== null && document.getElementById("omise-paynow").checked) {
        $("#loading-popup").modal("toggle")
        $(".loading-popup-img").show()
        Omise.setPublicKey(this.state.settings.omise_paynow_public_key)
        var calc_grand_total = this.getApplyRewardsPaymentTotal(this.state.newVoucher != "1" ? parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount) : parseFloat(this.state.cartDetails.cart_sub_total))
        /*Calculate service charge End*/
        calc_grand_total = parseInt(Math.round(calc_grand_total * 100))
        var current = this
        Omise.createSource(
          "paynow",
          {
            amount: calc_grand_total,
            currency: "SGD",
            name: cookie.load("UserFname"),
            email: cookie.load("UserEmail"),
          },
          function (statusCode, response) {
            if (statusCode == 200) {
              var sourceId = response.id
              var postObject = {}
              var orderOutlet_Id = cookie.load("orderOutletId")
              postObject = {
                app_id: APPID,
                source_id: sourceId,
                customer_id: cookie.load("UserId"),
                outlet_id: orderOutlet_Id,
                amount: response.amount,
                customer_name: cookie.load("UserFname"),
                customer_email: cookie.load("UserEmail"),
              }

              if (current.state.statusInterval != null) {
                clearInterval(current.state.statusInterval)
                current.setState({
                  paynowAttempts: 0,
                })
              }

              axios
                .post(APIURL + "paymentv1/paynowCapture", qs.stringify(postObject))
                .then((res) => {
                  if (res.data.status === "ok") {
                    $("#loading-popup").modal("toggle")
                    $(".loading-popup-img").hide()
                    $("#paynow-qr-image")
                      .attr("src", res.data.result_set.download_uri)
                      .load(function () {
                        $(".omise-paynow-amount-to-paid span").text("SGD " + (calc_grand_total / 100).toFixed("2"))
                        $(".alert-payment").hide()
                        $("#omise-paynow-popup").modal({ backdrop: "static", keyboard: false })
                        $(".paynow-seconds span").text("600 sec")
                        $(".paynow-seconds span").css({ "padding-left": "4px", display: "inline-block", width: "70px", "text-align": "left" })
                        var timer = 600

                        /* var timerInterval	=	setInterval(function() {
						  if(timer < 0) {
							  clearInterval(timerInterval);
							  clearInterval(statusInterval);
						  } else {
							  $('.paynow-seconds span').text(timer+' sec');
							  timer	=	timer-1;
						  }
					  },1000);*/
                        current.setState({ paymentGateway: "Omise" })
                        //current.postOrder(5,'No');
                        current.state.statusInterval = setInterval(() => {
                          if (current.state.paynowAttempts >= 100) {
                            clearInterval(current.state.statusInterval)
                            $("#omise-paynow-popup").modal("toggle")

                            current.setState({
                              paynowAttempts: 0,
                            })
                            alert("Time has been expired. Please try again.")
                            window.location.reload(false)
                            return
                          }

                          if (current.state.paynowAttempts == 1) {
                            $(".alert-payment").show()
                          }

                          if (timer < 0) {
                            clearInterval(current.state.statusInterval)
                          } else {
                            console.log(current.state.paynowAttempts)
                            $(".paynow-seconds span").text(timer + " sec")
                            timer = timer - 1
                          }
                          var r = timer % 6
                          if (r == 0) {
                            var statusObj = {}
                            statusObj = {
                              app_id: APPID,
                              customer_id: cookie.load("UserId"),
                              outlet_id: orderOutlet_Id,
                              capture_id: res.data.result_set.captureId,
                            }
                            axios.post(APIURL + "paymentv1/checkPaynowStatus", qs.stringify(statusObj)).then((statusRes) => {
                              if (statusRes.data.result_set.omise_paynow_status) {
                                clearInterval(current.state.statusInterval)
                                current.setState({ omisePayNowStatus: 2 })
                                current.setState({ payNowCaptureId: res.data.result_set.captureId })

                                $("#omise-paynow-popup").modal("toggle")
                                $("#SwitchingError").modal("toggle")
                                current.postOrder(5, "No")
                                current.setState({
                                  paynowAttempts: 101,
                                })
                              } else {
                                var pState = current.state.paynowAttempts + 1
                                current.setState({
                                  paynowAttempts: pState,
                                })
                              }
                            })
                          }
                        }, 1000)
                      })
                  }
                })
                .catch((error) => {})
            } else {
              console.log(response)
            }
          }
        )
      } else {
        helper.showInfo(lang.choose_payment_mode)
      }
    }
  }

  /* Omise Payment Gateway Start */
  handleChangeTxt = (item, event) => {
    const re = /^[0-9 \b]+$/
    if (item == "cardNumber") {
      /*if (event.target.value === '' || re.test(event.target.value)) {*/
      //var cardnumber = this.space(event.target.value);
      var cardnumberLenght = event.target.value.length
      if (cardnumberLenght <= 16) {
        this.setState({ [item]: event.target.value })
        this.cardValidation(event.target.value)
      }
      /*}*/
    } else if (item == "expiration_month") {
      if (event.target.value === "" || (re.test(event.target.value) && event.target.value.length <= 2)) {
        this.setState({ [item]: event.target.value })
      }
    } else if (item == "expiration_year") {
      if (event.target.value === "" || (re.test(event.target.value) && event.target.value.length <= 4)) {
        this.setState({ [item]: event.target.value })
      }
    } else if (item == "security_code") {
      if (event.target.value === "" || (re.test(event.target.value) && event.target.value.length <= 4)) {
        this.setState({ [item]: event.target.value })
      }
    } else {
      this.setState({ [item]: event.target.value })
    }
  }
  space(el) {
    var numbes = el.replace(/ /g, "")
    return numbes.replace(/(\d{4})/g, "$1 ").replace(/(^\s+|\s+$)/, "")
  }

  cardValidation(carnumber) {
    var imagename = ""
    if (carnumber != "") {
      var single = carnumber.substring(0, 1)
      var double = carnumber.substring(0, 2)

      if (single == 4) {
        imagename = "visa.png"
      } else if (double == 34 || double == 37) {
        imagename = "american-express.png"
      } else if (double >= 51 && double <= 55) {
        imagename = "master-card.png"
      } else if (double == 60 || double == 64 || double == 65) {
        imagename = "discover.png"
      }
      if (imagename != "") {
        this.setState({ cardImage: htmlToReactParserTo.parse('<img src="img/cards/' + imagename + '" className="card-image" alt="" />') })
      } else {
        this.setState({ cardImage: "" })
      }
    } else {
      this.setState({ cardImage: "" })
    }
  }

  /* Generate Token */
  getOmiseToken() {
    var error = 0
    if (this.state.holdername == "") {
      error++
      this.setState({ omisenameerror: htmlToReactParserTo.parse('<span className="spn-error">This field is required.</span>') })
    } else {
      this.setState({ omisenameerror: "" })
    }

    if (this.state.cardNumber == "") {
      error++
      this.setState({ omisecardrror: htmlToReactParserTo.parse('<span className="spn-error">This field is required.</span>') })
    } else {
      this.setState({ omisecardrror: "" })
    }

    if (this.state.expiration_month == "") {
      error++
      this.setState({ omisemontherror: htmlToReactParserTo.parse('<span className="spn-error">Required.</span>') })
    } else {
      this.setState({ omisemontherror: "" })
    }

    if (this.state.expiration_year == "") {
      error++
      this.setState({ omiseyearerror: htmlToReactParserTo.parse('<span className="spn-error">Required.</span>') })
    } else {
      this.setState({ omiseyearerror: "" })
    }

    if (this.state.security_code == "") {
      error++
      this.setState({ omisecodeerror: htmlToReactParserTo.parse('<span className="spn-error">This field is required.</span>') })
    } else {
      this.setState({ omisecodeerror: "" })
    }
    if (error > 0) {
      return false
    } else {
      $("#omisepopup .btn.bg-black").button("loading")
      Omise.setPublicKey(this.state.settings.omise_public_key)
      var current = this
      var card = {
        name: this.state.holdername,
        number: this.state.cardNumber,
        expiration_month: this.state.expiration_month,
        expiration_year: this.state.expiration_year,
        security_code: this.state.security_code,
        livemode: false,
      }
      Omise.createToken("card", card, function (statusCode, response) {
        $("#omisepopup .btn.bg-black").button("reset")
        if (statusCode === 200) {
          if (response.object == "error" || !response.card.security_code_check) {
            var message_text = "Invalid card details."

            if (response.object == "error") {
              message_text = response.message
            }
            helper.showInfo(message_text)
          } else {
            $("#omisepopup").modal("hide")
            //current.onProcessOmiseToken(response);
            current.setState({ omise_tokken_response: response, omise_tokken_card_id: response.card.id, omise_tokken_id: response.id }, () => {
              current.onProcessOmiseToken(response)
            })
          }
        } else {
          helper.showInfo(response.message)
        }
      })
    }
  }
  /* Authentication For Payment */
  onProcessOmiseToken = (token) => {
    var calc_grand_total = this.getApplyRewardsPaymentTotal(this.state.newVoucher != "1" ? parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount) : parseFloat(this.state.cartDetails.cart_sub_total))

    /*Calculate service charge End*/
    if (this.isApplyRewardOnGrandTotal()) {
      calc_grand_total = calc_grand_total
    } else {
      calc_grand_total = calc_grand_total.toFixed(2)
    }

    /*  load process html */
    this.setState({ paymentStatus: "ONLINE" })
    this.setState({ defaultClass: "chk_popup_bg" })
    $("#SwitchingError").modal("toggle")
    axios.get(APIURL + "callcenterapi/get_payment_reference_id?app_id=" + APPID).then((res) => {
      if (res.data.status === "ok") {
        var paymentRef = res.data.payment_ref_id
        this.setState({ payment_ref_id: paymentRef })

        var redirectUrl = window.location.protocol + "//" + window.location.hostname + "/#/checkout?callback_omise=1"
        var orderOutlet_Id = cookie.load("orderOutletId")
        var postObject = {}
        postObject = {
          app_id: APPID,
          token: token.id,
          customer_id: cookie.load("UserId"),
          paid_amount: calc_grand_total,
          outlet_name: typeof cookie.load("orderOutletName") !== "undefined" ? cookie.load("orderOutletName") + " - " + paymentRef : "",
          redirect_url: redirectUrl,
          outlet_id: orderOutlet_Id,
        }

        axios
          .post(APIURL + "paymentv1/authOmise", qs.stringify(postObject))
          .then((res) => {
            if (res.data.status === "ok") {
              /* Reset poup message -  start */
              this.setState({ paymentLoading: "/img/tick.png" })
              this.setState({ paymentGateway: "Omise" })
              /* Reset poup message -  end  */
              if (res.data.result_set.authorize_uri != "") {
                cookie.save("omise_capture_id", res.data.result_set.payment_reference_1)
                window.location.href = res.data.result_set.authorize_uri
              } else {
                var captureID = res.data.result_set.payment_reference_1
                this.setState({ log_id: res.data.result_set.log_id })
                this.postOrder(3, "", captureID)
              }
            } else if (res.data.status === "error") {
              /* Reset poup message -  start */
              $("#SwitchingError").modal("toggle")
              this.setState({ defaultClass: "" })
              /* Reset poup message -  end  */

              if (res.data.message == "token was already used") {
                var omiseSearchAPICal = parseInt(this.state.omiseSearchAPICal) + 1
                if (omiseSearchAPICal <= 150) {
                  this.setState({ omiseSearchAPICal: omiseSearchAPICal }, function () {
                    this.omise_search_history()
                  })
                } else {
                  helper.showInfo("Something is wrong. Try again")
                }
              } else {
                helper.showInfo(res.data.message)
              }
            } else {
              var currents = this
              setTimeout(
                function () {
                  var omiseSearchAPICal = parseInt(currents.state.omiseSearchAPICal) + 1
                  if (omiseSearchAPICal <= 150) {
                    currents.setState({ omiseSearchAPICal: omiseSearchAPICal }, function () {
                      currents.omise_search_history()
                    })
                  } else {
                    helper.showInfo("Something is wrong. Try again")
                  }
                }.bind(this),
                2000
              )
            }
          })
          .catch((error) => {
            var currentcatch = this
            setTimeout(
              function () {
                var omiseSearchAPICal = parseInt(currentcatch.state.omiseSearchAPICal) + 1
                if (omiseSearchAPICal <= 150) {
                  currentcatch.setState({ omiseSearchAPICal: omiseSearchAPICal }, function () {
                    currentcatch.omise_search_history()
                  })
                } else {
                  helper.showInfo("Something is wrong. Try again")
                }
              }.bind(this),
              2000
            )
          })
      }
    })
  }

  omise_search_history = () => {
    var orderOutlet_Id = cookie.load("orderOutletId")
    var postObject = {}
    postObject = {
      app_id: APPID,
      card_id: this.state.omise_tokken_card_id,
      token_id: this.state.omise_tokken_id,
      outlet_id: orderOutlet_Id,
    }

    axios.post(APIURL + "paymentv1/omise_search_details", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok") {
        this.setState({ paymentLoading: "/img/tick.png" })
        this.setState({ paymentGateway: "Omise" })
        /* Reset poup message -  end  */

        var captureID = res.data.captureID
        this.setState({ log_id: res.data.log_id })
        this.postOrder(3, "", captureID)
      } else if (res.data.status === "error") {
        /* Reset poup message -  start */
        // this.onProcessOmiseToken(this.state.omise_tokken_response);
      } else {
        //this.onProcessOmiseToken(this.state.omise_tokken_response);
      }
    })
  }

  /* Capture Payment */
  captureOmiseAmount(captureID, orderPrimaryId, localOrderNo) {
    var cabtureObjects = {}
    var captureParams = captureID + "~" + orderPrimaryId + "~" + localOrderNo
    var orderOutlet_Id = cookie.load("orderOutletId")
    cabtureObjects = { payment_reference: config.stripe.stripeReference, customer_id: cookie.load("UserId"), app_id: config.ids.appId, token: captureID, order_id: orderPrimaryId, log_id: this.state.log_id, outlet_id: orderOutlet_Id }
    axios
      .post(APIURL + "paymentv1/captureAmountOmise", qs.stringify(cabtureObjects))
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.showSuccessPage(localOrderNo, orderPrimaryId)
        } else if (captureRes.data.status === "pending") {
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        } else if (captureRes.data.status === "error") {
          $("#SwitchingError").modal("toggle")
          document.getElementById("PayProcess").style.display = "none"
          this.setState({ defaultClass: "" })
          this.setState({ status: "error" })
          /*helper.showInfo(res.data.status);*/
          var msgTxt = res.data.message != "" ? res.data.message : res.data.status
          helper.showInfo(msgTxt)
        } else {
          /*Get Status of Charge ID*/
          this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
        }
      })
      .catch((error) => {
        this.captureOmiseAmount(captureID, orderPrimaryId, localOrderNo)
      })
  }

  retrieve_charge_details = (captureID, localOrderNo, orderPrimaryId) => {
    var orderOutlet_Id = cookie.load("orderOutletId")
    var postObject = {}
    postObject = { charge_id: captureID, app_id: APPID, order_primary_id: orderPrimaryId, outlet_id: orderOutlet_Id }

    axios.post(APIURL + "paymentv1/retrieve_charge_details", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok" && res.data.payment_status === "successful") {
        this.showSuccessPage(localOrderNo, orderPrimaryId)
      } else if (res.data.status === "ok" && res.data.payment_status === "pending") {
        this.retrieve_charge_details(captureID, localOrderNo, orderPrimaryId)
      } else {
        document.getElementById("PayProcess").style.display = "none"
        this.setState({ defaultClass: "" })
        this.setState({ status: "error" })
        /*helper.showInfo(res.data.status);*/
        var msgTxt = res.data.message != "" ? res.data.message : res.data.status
        helper.showInfo(msgTxt)
      }
    })
  }

  retrieve_three_d_charges = (captureID) => {
    var orderOutlet_Id = cookie.load("orderOutletId")
    var postObject = {}
    postObject = { charge_id: captureID, app_id: APPID, outlet_id: orderOutlet_Id }

    axios.post(APIURL + "paymentv1/retrieve_three_d_charges", qs.stringify(postObject)).then((res) => {
      if (res.data.status === "ok" && res.data.payment_status === "successful") {
        this.setState({ omisePayment: 1 })
        var captureID = cookie.load("omise_capture_id")
        this.postOrder(3, "", captureID)
        cookie.save("omise_capture_id", "")
      } else if (res.data.status === "ok" && res.data.payment_status === "pending") {
        this.setState({ omisePayment: 1 })
        var captureID = cookie.load("omise_capture_id")
        this.postOrder(3, "", captureID)
        cookie.save("omise_capture_id", "")
      } else {
        var redirectUrl = window.location.protocol + "//" + window.location.hostname + ":3000/#/checkout"
        window.location.href = redirectUrl
        //document.getElementById("PayProcess").style.display = "none";
        this.setState({ defaultClass: "" })
        this.setState({ status: "error" })
        /*helper.showInfo(res.data.status);*/
        var msgTxt = res.data.message != "" ? res.data.message : res.data.status
        helper.showInfo(msgTxt)
      }
    })
  }

  /* Enable Omise */
  enableOmise() {
    var omiseSts = parseInt(this.state.settings.client_omise_enable)
    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var stripeAvl = parseInt(this.state.settings.client_stripe_availability)
    var codAvl = parseInt(this.state.settings.client_cod_availability)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    var omiseChecked = (codSts === 0 || codAvl === 0) && (stripeSts === 0 || stripeAvl === 0) ? true : false
    if ((omiseSts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) || (omiseSts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1)) {
      return (
        <label>
          <input type="radio" name="payment" id="omise" defaultChecked={omiseChecked} value="OMISE" onClick={this.showCheckoutBtn.bind(this, "OMISE")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/cards/omise.png" className="img-responsive img-width-120" alt="omise" />
        </label>
      )
    }
  }
  enableRazerpay() {
    var isEnabled = parseInt(this.state.settings.razerpay_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (isEnabled && parseFloat(calc_grand_total) > 0) {
      const script_r = document.createElement("script")
      console.info(this.state.settings.razerpay_mode)
      if (this.state.isRazerpayScript == false) {
        this.setState({ isRazerpayScript: true })
        if (this.state.settings.razerpay_mode == "live") {
          script_r.src = "https://www.onlinepayment.com.my/MOLPay/API/seamless/latest/js/MOLPay_seamless.deco.js"
        } else {
          script_r.src = "https://sandbox.merchant.razer.com/MOLPay/API/seamless/latest/js/MOLPay_seamless.deco.js"
        }

        script_r.async = true
        document.body.appendChild(script_r)
      }

      return (
        <label>
          <input type="radio" name="payment" id="razerpay" value="RAZERPAY" onClick={this.showCheckoutBtn.bind(this, "RAZERPAY")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/cards/razerpay.jpg" className="img-responsive img-width-120" alt="RAZERPAY" />
          <div id="molpay_btn"></div>
        </label>
      )
    }
  }
  razerpaySts() {
    var isEnabled = parseInt(this.state.settings.razerpay_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (isEnabled && parseFloat(calc_grand_total) > 0) {
      return "block"
    } else {
      return "none"
    }
  }

  enableOmisePayNow() {
    var isPayNowEnabled = parseInt(this.state.settings.client_omise_enable_paynow)
    var omiseChecked = false
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (isPayNowEnabled === 1 && parseFloat(calc_grand_total) > 0) {
      return (
        <label>
          <input type="radio" name="payment" id="omise-paynow" defaultChecked={omiseChecked} value="OMISE-PAYNOW" onClick={this.showCheckoutBtn.bind(this, "OMISE-PAYNOW")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/omise-paynow.png" className="img-responsive img-width-120" alt="omise" />
        </label>
      )
    }
  }
  omiseSts() {
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var omiseStsVl = parseInt(this.state.settings.client_omise_enable)
    var omiseAvl = parseInt(this.state.settings.client_omise_availability)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (((codSts === 1 && omiseStsVl === 1) || (codSts === 1 && omiseStsVl === 0)) && parseFloat(calc_grand_total) <= 0) {
      return "none"
    } else {
      return omiseStsVl == 1 && omiseAvl === 1 ? "block" : "none"
    }
  }

  /* Omise Payment Gateway End */

  /* Show checkout page */
  showCheckoutBtn(type) {
    this.setState({ omisePayment: 0 })
    document.getElementById("cash_btn").style.display = "none"
    document.getElementById("stripe_btn").style.display = "none"
    document.getElementById("koomipay_btn").style.display = "none"
    // document.getElementById("paypal_btn").style.display = "none"
    document.getElementById("omise_btn").style.display = "none"
    document.getElementById("razerpay_btn").style.display = "none"
    document.getElementById("omise_btn_paynow").style.display = "none"
    if (type === "CASH") {
      $("#extraBtn").show()
      //console.log(type);
      document.getElementById("cash_btn").style.display = "block"
      this.cal_service_charge(1)
    }
    if (type === "OFFLINE") {
      $("#extraBtn").show()
      $("#offlinePaymentInfo").modal("toggle")
      document.getElementById("cash_btn").style.display = "block"

      this.cal_service_charge(1)
    } else if (type === "STRIPE") {
      $("#extraBtn").show()
      document.getElementById("stripe_btn").style.display = "block"

      this.cal_service_charge(3)
    } else if (type === "KOOMIPAY") {
      // $("#extraBtn").show()
      const koomipayBtn = document.getElementById("koomipay_btn")
      koomipayBtn.style.display = "block"
      this.setState({ koomipayPayment: true }, () => {
        this.postOrder(3, "Yes")
        window.scrollTo({ top: koomipayBtn.getBoundingClientRect().top + 500 })
      })

      // this.cal_service_charge(3)
    } else if (type === "PAYPAL") {
      $("#extraBtn").hide()
      document.getElementById("paypal_btn").style.display = "block"
      this.cal_service_charge(2)
    } else if (type === "OMISE") {
      this.setState({ omisePayment: 1 })
      document.getElementById("omise_btn").style.display = "block"

      this.cal_service_charge(3)
    } else if (type === "OMISE-PAYNOW") {
      this.setState({ omisePayment: 1 })
      document.getElementById("omise_btn_paynow").style.display = "block"

      this.cal_service_charge(5)
    } else if (type === "RAZERPAY") {
      this.setState({ omisePayment: 1 })
      document.getElementById("razerpay_btn").style.display = "block"

      this.cal_service_charge(5)
    }
  }

  /* this function used back nav link for all avilablities */
  backLink() {
    /* Validate delivery order details */
    if (this.state.cartDetails.cart_availability_id === config.ids.deliveryId || this.state.cartDetails.cart_availability_id === config.ids.pickupId) {
      return "delivery-date"
    } else {
      if (APPID === "287E5552-328E-4DBE-A3CA-E998ACCAC9BD") {
        return "/"
      } else {
        return "cart"
      }
    }
  }

  /* this function nused to load */

  showOrderDetails() {
    var labelThree = ""
    var labelThreeVal = ""
    if (this.state.cartDetails.cart_availability_id !== config.ids.dineinId) {
      var labelOne = cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Delivery Date" : "Pickup Date"
      var labelOneVal = cookie.load("DeliveryDate")
      var labelTwo = cookie.load("defaultAvilablityId") === config.ids.deliveryId ? "Delivery Time" : "Pickup Time"
      //<Moment format="HH:mm">{cookie.load('DeliveryTimeSlot')}</Moment>
      /*var labelTwoVal = (cookie.load('isSletedTimeSlot') === "Yes"?  cookie.load('DeliveryTimeSlotFormated') :   cookie.load('DeliveryTime'));*/
      var labelTwoVal = cookie.load("isSletedTimeSlot") === "Yes" ? cookie.load("DeliveryTimeSlotFormated") : cookie.load("DeliveryTime")

      return (
        <div className="col-xs-12">
          <div className="col-sm-6  date-info">
            <p className="">
              {labelOne}:<span className="span-txt1 small-font"> {labelOneVal}</span>
            </p>

            {labelThree}
            <p className="visible-xs">
              <span>{labelTwo}: </span>
              <span className="span-txt1 small-font">{labelTwoVal}</span>
            </p>
          </div>
          <div className="col-sm-6 hidden-xs date-info">
            <p className="text-right">
              <span>{labelTwo}: </span>
              <span className="span-txt1">{labelTwoVal}</span>
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-xs-12">
          <div className="col-sm-6  date-info">
            <p className="">
              Dine in Date: <span className="span-txt1"> {currentDate}</span>
            </p>
            <p className="visible-xs">
              <span>Current Time : </span>
              <span className="span-txt1">{currentTime}</span>
            </p>
            <p className="">
              Table : <span className="span-txt1">{cookie.load("orderTableNo")}</span>
            </p>
          </div>
          <div className="col-sm-6 hidden-xs date-info">
            <p className="text-right">
              <span>Current Time : </span>
              <span className="span-txt1">{currentTime}</span>
            </p>
            <p className="text-right">
              &nbsp;<span className="span-txt1">&nbsp;</span>
            </p>
          </div>
        </div>
      )
    }
  }

  /* show online payment mode loading */
  onlinePaymentLoading() {
    if (this.state.paymentStatus === "ONLINE") {
      return (
        <li>
          <div className="img">
            <img src={this.state.paymentLoading} alt="" />
          </div>
          <div className="cont">
            <p className="date">
              {currentDate} <span className="time">{currentTime}</span>
            </p>
            <p>Waiting for your online payment to be processed.</p>
          </div>
        </li>
      )
    }
  }
  /**/
  enableCOD() {
    //alert(parseFloat(this.state.cartDetails.cart_grand_total));
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))

    if (
      (parseInt(this.state.settings.client_cod_enable) === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) ||
      (parseInt(this.state.settings.client_cod_enable) === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_cod_availability) === 1)
    ) {
      return (
        <label>
          {" "}
          <input type="radio" name="payment" id="cash" defaultChecked={true} value="CASH" onClick={this.showCheckoutBtn.bind(this, "CASH")} />
          <span className="circle"></span>
          <span className="check"></span>
          {APPID == "B637EE61-A5A9-4303-ACF7-4A3C055DC845" ? <img src="/img/honolulu_cash_pay.png" className="img-responsive img-width-120" alt="cash-on-delivery" /> : <img src="/img/cash-on-delivery.png" className="img-responsive img-width-120" alt="cash-on-delivery" />}
        </label>
      )
    }
  }

  /* enable stripe */
  enableStripe() {
    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))

    var retSts = codSts === 0 || parseInt(this.state.settings.client_cod_availability) === 0 ? true : false
    if (
      (stripeSts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) ||
      (stripeSts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_stripe_availability) === 1)
    ) {
      return (
        <label>
          <input type="radio" name="payment" id="stripe" defaultChecked={retSts} value="STRIPE" onClick={this.showCheckoutBtn.bind(this, "STRIPE")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/cards/visa-logo.png" className="img-responsive img-width-120" alt="visa-card" />
        </label>
      )
    }
  }

  enableKoomipay() {
    var koomipaySts = parseInt(this.state.settings.client_enable_koomipay)
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))

    var retSts = false
    if ((koomipaySts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) || (koomipaySts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1)) {
      return (
        <label>
          <input type="radio" name="payment" id="koomipay" defaultChecked={retSts} value="KOOMIPAY" onClick={this.showCheckoutBtn.bind(this, "KOOMIPAY")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/cards/koomipay.png" className="img-responsive img-width-120" alt="visa-card" />
        </label>
      )
    }
  }

  enablePaypal() {
    var paypalSts = parseInt(this.state.settings.client_paypal_enable)
    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var stripeAvl = parseInt(this.state.settings.client_stripe_availability)
    var codAvl = parseInt(this.state.settings.client_cod_availability)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    var paySts = (codSts === 0 || codAvl === 0) && (stripeSts === 0 || stripeAvl === 0) ? true : false
    if (paypalSts === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_paypal_availability) === 1) {
      return (
        <label>
          <input type="radio" name="payment" id="paypal" defaultChecked={paySts} value="PAYPAL" onClick={this.showCheckoutBtn.bind(this, "PAYPAL")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/paypal-logo.png" className="img-responsive img-width-120" alt="Paypal" />
        </label>
      )
    }
  }

  enableOffline() {
    var paypalSts = parseInt(this.state.settings.client_paypal_enable)
    var omiseSts = parseInt(this.state.settings.omise_stripe_availability)
    var client_offline_enable = parseInt(this.state.settings.client_offline_enable)
    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var stripeAvl = parseInt(this.state.settings.client_stripe_availability)
    var codAvl = parseInt(this.state.settings.client_cod_availability)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (isNaN(omiseSts)) {
      omiseSts = 0
    }
    let offlineStatus = client_offline_enable == 1 && codAvl === 0 && omiseSts === 0 && paypalSts === 0 && stripeSts === 0 && stripeAvl === 0 ? false : false

    offlineStatus = APPID === "C655A75D-2E68-4E31-BCE1-58F533E0D1A7" ? false : offlineStatus
    if (client_offline_enable === 1 && parseFloat(calc_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1) {
      return (
        <label>
          <input type="radio" name="payment" id="offline" defaultChecked={offlineStatus} value="OFFLINE" onClick={this.showCheckoutBtn.bind(this, "OFFLINE")} />
          <span className="circle"></span>
          <span className="check"></span>
          <img src="/img/offline-delivery.png" className="img-responsive img-width-120" alt="Offline Payment" />
        </label>
      )
    }
  }

  /* check stripe status */
  cashSts() {
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))

    var codAvl = parseInt(this.state.settings.client_cod_availability)
    if (((codSts === 1 && stripeSts === 1) || (codSts === 1 && stripeSts === 0)) && parseFloat(calc_grand_total) > 0) {
      return codSts === 1 && codAvl === 1 ? "block" : "none"
    } else {
      return "none"
    }
  }
  //offlineSts
  offlineSts() {
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var codAvl = parseInt(this.state.settings.client_cod_availability)

    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var stripeAvl = parseInt(this.state.settings.client_stripe_availability)

    var isOffline = parseInt(this.state.settings.client_offline_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (((codSts === 1 && codAvl === 1) || (stripeSts === 1 && stripeAvl === 1)) && parseFloat(calc_grand_total) > 0) {
      return "none"
    } else {
      return isOffline === 1 ? "block" : "none"
    }
  }

  /* show cash status stripeSts */
  stripeSts() {
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var stripeAvl = parseInt(this.state.settings.client_stripe_availability)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (((codSts === 1 && stripeSts === 1) || (codSts === 1 && stripeSts === 0)) && parseFloat(calc_grand_total) <= 0) {
      return "none"
    } else {
      return stripeSts == 1 && stripeAvl === 1 ? "block" : "none"
    }
  }

  paypalSts() {
    var codSts = parseInt(this.state.settings.client_cod_enable)
    var codAvl = parseInt(this.state.settings.client_cod_availability)

    var stripeSts = parseInt(this.state.settings.client_stripe_enable)
    var stripeAvl = parseInt(this.state.settings.client_stripe_availability)

    var paypalSts = parseInt(this.state.settings.client_paypal_enable)
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (((codSts === 1 && codAvl === 1) || (stripeSts === 1 && stripeAvl === 1)) && parseFloat(calc_grand_total) > 0) {
      return "none"
    } else {
      return paypalSts === 1 ? "block" : "none"
    }
  }

  /* enable promo buttoon */
  promoSts() {
    var calc_grand_total = this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount))
    if (parseFloat(calc_grand_total) <= 0) {
      return "block"
    } else {
      return "none"
    }
  }

  promoButton() {
    if (this.state.promoApplied === "Yes" && this.state.promoType === "PROMO") {
      return (
        <button className="btn bg-black btn-apply" onClick={this.resetPromoState.bind(this, this.state.cartDetails.cart_sub_total)}>
          Remove
        </button>
      )
    } else {
      if (this.state.promoType !== "POINTS") {
        return (
          <button className="btn bg-black btn-apply applyPromo" onClick={this.applyCoupon.bind(this, this.state.cartDetails.cart_sub_total, "")}>
            Apply
          </button>
        )
      }
    }
  }

  /* Show  reward button */
  pointsButton() {
    if (this.state.promoApplied === "Yes" && this.state.promoType === "POINTS") {
      return (
        <button className="btn bg-black  btn-apply" onClick={this.resetPromoState.bind(this, this.state.cartDetails.cart_sub_total)}>
          Remove
        </button>
      )
    } else {
      if (this.state.promoType !== "PROMO") {
        return (
          <button className="btn bg-black btn-apply" onClick={this.applyRewards.bind(this)}>
            Apply
          </button>
        )
      }
    }
  }

  advocadopopupreg() {
    $("#advocadopopup").modal("toggle")
  }

  /* select payment text */
  selectPaymentText() {
    if (parseFloat(this.state.cartDetails.cart_grand_total) > 0) {
      return <p className="text-center">Please select your payment method</p>
    }
  }

  /* enable promotion payment */
  enablePromotion() {
    var calc_grand_total = parseFloat(this.getApplyRewardsPaymentTotal(parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)))
    if (parseFloat(calc_grand_total) <= 0) {
      return <p>{alertMsg.promoPaymentText}</p>
    }
  }

  /* Hide  drop down work space site */
  hidedropDown(type) {
    if (type === "sub" && APPID === apps.zazzpizza) {
      return "none"
    } else {
      return APPID === apps.workspace ? "none" : "block"
    }
  }

  /* add class "col-sm-6 padd1 "*/
  addClass(type, rewardMenustatus) {
    if (APPID === "8F8FE4E6-88C9-4071-9215-312E0F50016A") {
      return APPID == apps.workspace || APPID === apps.zazzpizza ? "  padd1" : "col-sm-6 col-md-offset-3 padd1"
    } else {
      return APPID == apps.workspace || APPID === apps.zazzpizza || rewardMenustatus === false ? "  padd1" : "col-sm-6 padd1"
    }
  }

  changePromoVal(e) {
    this.setState({ appliedPromocode: e.target.value })
  }

  changeRewardVal(e) {
    this.setState({ promoPoints: e.target.value })
  }

  advocadopin(e) {
    this.setState({ advocadopin: e.target.value })
  }

  showPromoPart() {
    var usertype = typeof cookie.load("userAccountType") !== "undefined" ? cookie.load("userAccountType") : 0
    let rewardMenustatus = helper.disableMenu(this.state.settings, "checkout_rewards")
    let promoMenustatus = helper.disableMenu(this.state.settings, "checkout_promos")

    if (!rewardMenustatus && !promoMenustatus) {
      return false
    }

    return (
      <div className="col-sm-12 no-padding main-outer">
        <div className="reward_row clearfix" style={{ display: this.hidedropDown("main") }}>
          <h3 style={{ display: this.hidedropDown("main") }} className="inner-title-1">
            CHECK YOUR REWARDS & PROMOTIONS
          </h3>

          {APPID !== "8F8FE4E6-88C9-4071-9215-312E0F50016A" && rewardMenustatus && this.state.enable_advocado !== "Yes" && (
            <div style={{ display: this.hidedropDown("sub") }} className={!promoMenustatus ? " col-sm-12 padd1 " : " col-sm-6 padd1 "}>
              <div className="redeem_points_option" id="redeem_points_option">
                <h4 className="text-center reward_smtt">Redeem Rewards Points</h4>
                <div className="promotion_formgroup">
                  <input type="text" className="txt-field" id="points" placeholder={"Redeem " + parseFloat(this.state.customerPoints) + " Points"} value={this.state.promoPoints > 0 ? this.state.promoPoints : ""} onChange={this.changeRewardVal.bind(this)} />
                </div>
                <p className="text-center">{this.pointsButton()}</p>
              </div>

              <div className="redeem_point_otp" id="redeem_point_otp_div" style={{ display: "none" }}>
                <h4 className="text-center reward_smtt">Enter your OTP</h4>
                <div className="promotion_formgroup">
                  <input type="text" className="txt-field" id="redeem_points_otp" placeholder="Enter valid OTP" />
                </div>
                <a className="resent_redeem_otp" id="resend_otp_button" onClick={this.handleResendotp.bind(this)}>
                  <b>Resent OTP</b>
                </a>
                <p className="text-center">
                  <button className="btn bg-black btn-apply" onClick={this.otp_verification.bind(this)}>
                    Submit
                  </button>
                  <button className="btn bg-black btn-apply cancel_otp_val" onClick={this.otp_cancel_option.bind(this)}>
                    Cancel
                  </button>
                </p>
              </div>
            </div>
          )}

          {this.state.enable_advocado === "Yes" && (this.state.advocado_id === undefined || this.state.advocado_id === null) && (
            <div style={{ display: this.hidedropDown("sub") }} className="col-sm-6 padd1 ">
              <h4 className="text-center">&nbsp;</h4>
              <div className="row redeem_advocado_formgroup">
                <div className="redeem_signup_info text-center">
                  <h4>
                    DON'T HAVE A <b>REWARDS</b> ACCOUNT?
                  </h4>
                  <p>
                    <Link style={{ cursor: "pointer", textDecoration: "none", "font-size": "18px" }} onClick={this.advocadopopupreg.bind(this)}>
                      <b>Sign up</b>
                    </Link>{" "}
                    here for rewards.
                  </p>
                </div>
              </div>
            </div>
          )}

          {this.state.enable_advocado === "Yes" && this.state.advocado_id !== "undefined" && this.state.advocado_id !== null && (
            <div style={{ display: this.hidedropDown("sub") }} className="col-sm-6 padd1 ">
              <h4 className="text-center reward_smtt">Redeem Cashback</h4>
              <div className="row redeem_advocado_formgroup">
                <div className="col-xs-8 advocado-redeem-sec">
                  <input type="text" className="txt-field" id="points" placeholder={parseFloat(this.state.customerAdvocadoPoints) > 0 ? "Redeem $" + parseFloat(this.state.customerAdvocadoPoints) + " cashback" : "You have no cashback"} />

                  {/* typeof this.state.settings.client_reward_amount_per_point !== 'undefined' &&
									
									<div className="forgot_redeempin text-center">
										(1 point = {this.state.settings.client_reward_amount_per_point})
									</div>
									*/}
                </div>
                <div className="col-xs-4">
                  <input type="text" className="txt-field" id="points" placeholder={"Enter Pin"} onChange={this.advocadopin.bind(this)} />
                  <div className="forgot_redeempin text-center">
                    <Link onClick={this.advocadoforgot.bind(this)} title="Forgot Pin">
                      Forgot Pin
                    </Link>
                  </div>
                </div>
              </div>
              <p className="text-center">{this.pointsButton()}</p>
            </div>
          )}

          {promoMenustatus && (
            <div className={this.addClass(usertype, rewardMenustatus)}>
              <h4 className="text-center reward_smtt">Promotion</h4>
              <div className="promotion_formgroup">
                <input type="text" value={this.state.appliedPromocode} onChange={this.changePromoVal.bind(this)} className="txt-field" id="promocode" placeholder="Add Your Promo Code Here" />
              </div>
              <p className="text-center">{this.promoButton()}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  showGiftOption() {
    if (this.state.settings.enable_recipient !== "" && typeof this.state.settings.enable_recipient !== undefined && typeof this.state.settings.enable_recipient !== "undefined") {
      if (this.state.settings.enable_recipient === "1") {
        return (
          <div>
            <div className="gift-option">
              <div className="pull-left">
                <input type="checkbox" id="gift_option" onChange={this.selectGift.bind(this)} checked={this.state.gift_option} style={{ marginRight: "10px" }} />
                <label htmlFor="gift_option" className="css-label-chkbox">
                  {" "}
                  I would like to send this order as a gift
                </label>
              </div>
            </div>
            {this.state.gift_option === true && (
              <div className="col-sm-12 no-padding main-outer">
                <div className="gift_row clearfix">
                  <h3 className="inner-title-1">Gift</h3>
                  <div className="col-sm-6 padd1">
                    <h4 className="text-center reward_smtt">Recipient Name</h4>
                    <div className="promotion_formgroup">
                      <input type="text" className="txt-field" id="recipient_name" placeholder="Recipient Name" onChange={this.handleChangeTxt.bind(this, "recipient_name")} />
                    </div>
                  </div>
                  <div className="col-sm-6 padd1">
                    <h4 className="text-center reward_smtt">Recipient Contact Number</h4>
                    <div className="promotion_formgroup">
                      <input type="text" className="txt-field" id="recipient_contact_no" placeholder="Recipient Contact Number" onChange={this.handleChangeTxt.bind(this, "recipient_contact_no")} />
                    </div>
                  </div>
                  <div className="col-sm-12 padd1">
                    <h4 className="text-center reward_smtt">Message</h4>
                    <div className="promotion_formgroup">
                      <textarea type="text" id="gift_message" placeholder="Please enter your gift message here..." className="txt-field" cols="5" style={{ textAlign: "left" }} onChange={this.handleChangeTxt.bind(this, "gift_message")}></textarea>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      }
    }
  }

  selectGift(e) {
    this.setState({ gift_option: e.target.checked })
  }

  /*
 paypalLogMonitoring(status, response)
 {

	postObject = {
	'app_id' : APPID,
	'token' : token.id,
	}

  axios.post(APIURL+"Paypallogmonitoring/add_log", qs.stringify(postObject)).then(res => {

			//this.setState({status:""});
			   if(res.data.status === "ok"){

			      /* Reset poup message -  start
				 // console.log('paysuccess');
				  this.setState({'paymentLoading':'/img/tick.png'});

			     /* Reset poup message -  end

				 this.postOrder(2);
				 }
			  else if(res.data.status === "error") {
			   /* Reset poup message -  start
			    $("#SwitchingError").modal("toggle");
                this.setState({'defaultClass':''});
			     /* Reset poup message -  end
				helper.showInfo(res.data.message);
			 }
	  });
 } */

  showPDPA() {
    $(".pdpa").toggleClass("active")
  }

  paymentHide() {
    if (APPID === "CD8D5FE3-0586-44DD-9360-EE9DEF351223") {
      var hidePaymentMode = "none"
      if (
        (parseInt(this.state.settings.client_cod_enable) === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) ||
        (parseInt(this.state.settings.client_cod_enable) === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_cod_availability) === 1)
      ) {
        hidePaymentMode = ""
      }

      var stripeSts = parseInt(this.state.settings.client_stripe_enable)
      var codSts = parseInt(this.state.settings.client_cod_enable)

      var retSts = codSts === 0 || parseInt(this.state.settings.client_cod_availability) === 0 ? true : false
      if (
        (stripeSts === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) ||
        (stripeSts === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_stripe_availability) === 1)
      ) {
        //var hidePaymentMode = parseInt(this.state.hidePaymentMode)+1;
        //this.setState({hidePaymentMode:hidePaymentMode});
        hidePaymentMode = ""
      }

      var paypalSts = parseInt(this.state.settings.client_paypal_enable)
      var stripeSts = parseInt(this.state.settings.client_stripe_enable)
      var codSts = parseInt(this.state.settings.client_cod_enable)
      var stripeAvl = parseInt(this.state.settings.client_stripe_availability)
      var codAvl = parseInt(this.state.settings.client_cod_availability)
      var paySts = (codSts === 0 || codAvl === 0) && (stripeSts === 0 || stripeAvl === 0) ? true : false
      if (paypalSts === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1 && parseInt(this.state.settings.client_paypal_availability) === 1) {
        hidePaymentMode = ""
      }
      var omiseSts = parseInt(this.state.settings.client_omise_enable)
      var stripeSts = parseInt(this.state.settings.client_stripe_enable)
      var codSts = parseInt(this.state.settings.client_cod_enable)
      var stripeAvl = parseInt(this.state.settings.client_stripe_availability)
      var codAvl = parseInt(this.state.settings.client_cod_availability)
      var omiseChecked = (codSts === 0 || codAvl === 0) && (stripeSts === 0 || stripeAvl === 0) ? true : false
      if (
        (omiseSts === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 0) ||
        (omiseSts === 1 && parseFloat(this.state.cartDetails.cart_grand_total) > 0 && parseInt(this.state.settings.client_payment_availability_enable) === 1)
      ) {
        hidePaymentMode = ""
      }

      return hidePaymentMode
    }
  }

  getApplyRewardsPaymentTotal(payAbleAmount) {
    let returnAmount = payAbleAmount
    if (this.isApplyRewardOnGrandTotal()) {
      returnAmount = parseFloat(this.state.finalApplyRewardsOriginalGrandTotal).toFixed(2)
    }

    return returnAmount
  }

  render() {
    /* Render */

    const envSts = parseInt(this.state.settings.client_paypal_mode) === 0 ? "sandbox" : "production"
    var totalAmount = this.getApplyRewardsPaymentTotal(this.state.newVoucher != "1" ? (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2) : parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2))
    const client = {
      sandbox: this.state.settings.paypal_client_id,
      production: this.state.settings.paypal_client_id,
    }

    if (this.state.status === "Loading") {
      return (
        <div className="container-fluid desktop-container position-rel" style={{ minHeight: this.state.windowHeightNormal }}>
          <div className="row">
            <Loading />
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div id="loadIcon" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 9999, background: "rgba(255, 255, 255, 0.8)" }}>
            {" "}
            <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" /> <p className="validateOrder"> We are validating your order details. Please Wait.</p>{" "}
          </div>

          <div id="PromoLoading" style={{ display: "none", bottom: 0, left: 0, position: "fixed", right: 0, top: 0, margin: "auto", zIndex: 9999, background: "rgba(255, 255, 255, 0.8)" }}>
            {" "}
            <img style={{ bottom: 0, left: 0, position: "absolute", right: 0, top: 0, margin: "auto" }} src="/img/loader.gif" alt="Loading" />{" "}
          </div>

          <DocumentMeta {...meta} />
          <div className="container-fluid desktop-container" style={{ minHeight: this.state.windowHeight }}>
            <div className="row">
              <div className="col-xs-12 top-nav inner-top-nav">
                <div className="col-xs-3 nav-head pd-l-20">
                  {" "}
                  <Link to={this.backLink(this)}>
                    <img src="/img/icons/arrow-right.png" className="icon-img-small-3" alt="left-arrow" />
                  </Link>
                </div>

                <div className="col-xs-6 nav-head ">
                  <h1 className="main-title">CHECKOUT</h1>
                </div>
                <div className="col-xs-3 nopadding">
                  {/*  <p className="b-txt text-right ">

							<img src="/img/icons/shopping-cart.png" className="icon-img-small-1-inner" alt="shopping-cart"/>
						</p>  */}
                </div>
              </div>
            </div>

            <div className="row bg-white">
              <div className="col-sm-12 ">
                <div className="col-sm-12  main-outer margin-top-40 rewardbx_up">
                  <h3 className="inner-title-1">your order details</h3>
                  {APPID !== "B401FC82-8E39-4076-838A-55192F765004" && APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" && APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" && APPID !== "B637EE61-A5A9-4303-ACF7-4A3C055DC845" && (
                    <h4 className="text-center reward_smtt">Please Select Mode of Payment for Order Submission</h4>
                  )}
                  {APPID === "B637EE61-A5A9-4303-ACF7-4A3C055DC845" && (
                    <h4 style={{ fontWeight: "bold" }} className="text-center reward_smtt">
                      PLEASE MAKE PAYMENT AT COUNTER
                    </h4>
                  )}

                  <h4 className="text-center reward_smtt"></h4>
                  {this.showOrderDetails()}

                  <div className="col-sm-12 padd1 price-list">
                    {/*this.loadProductsDetails()*/}
                    {this.loadProductsDetails()}

                    {/* <tr>
										<td className="main-item">1 X Product Name</td>
										<td className="text-right">25.50</td>
									</tr>
									<tr>
										<td className="sub-item">Sub Item</td>
										<td></td>
									</tr>
									<tr>
										<td className="sub-item">Sub Item 2</td>
										<td></td>
									</tr>
									<tr>
										<td className="main-item">1 X Product Name</td>
										<td className="text-right">25.50</td>
									</tr>
									<tr className="sub-total-tr">
										<td className="sub-total">Sub Total</td>
										<td className="sub-total text-right">$76.50</td>
									</tr>
									<tr>
										<td className="total">Total</td>
										<td className="total text-right">$76.50</td>
									</tr> */}
                  </div>

                  {this.state.enable_advocado === "Yes" && this.state.advocado_id !== "undefined" && this.state.advocado_id !== null && parseFloat(this.state.advocado_cashbackPercent) > 0 && (
                    <div className="reward_offerlabel">
                      <img src="images/reward_offer.png" alt="" /> <span>You will Get ${(((parseFloat(this.state.originalSubTotal) - parseFloat(this.state.promoAmount)) * this.state.advocado_cashbackPercent) / 100).toFixed(2)} Cashback with this order</span>
                    </div>
                  )}
                </div>
                {cookie.load("defaultAvilablityId") === config.ids.deliveryId && this.showGiftOption()}
                {APPID !== "B401FC82-8E39-4076-838A-55192F765004" &&
                  APPID !== "6C0280A0-B655-4E0F-A43F-041C189683BA" &&
                  APPID !== "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" &&
                  APPID !== "33843AC9-EB47-42EE-8AAA-14CEA187D85A" &&
                  APPID !== "4EDE9A25-89AA-4200-848D-AC4A5A54B7BC" &&
                  this.state.customers_is_anonymous === 0 &&
                  this.showPromoPart()}

                <div style={{ display: APPID === "B401FC82-8E39-4076-838A-55192F765004" || APPID === "6C0280A0-B655-4E0F-A43F-041C189683BA" || APPID === "B93EC9A9-1A67-4EBA-ADB7-BC7C14FA88A4" ? "none" : "" }}>
                  <div className="col-sm-12 no-padding main-outer" style={{ display: this.paymentHide() }}>
                    {htmlToReactParserTo.parse(this.state.masterCardLogo)}
                    <h3 className="inner-title-2">PAYMENT</h3>
                    <div className="col-sm-12 padd1 ">
                      {this.selectPaymentText()}
                      <div className="space-10"></div>
                      <div className="cards center-block payment_gatesec" style={{ width: "100%" }}>
                        <div className="radio radio-primary">{this.enableCOD()}</div>

                        <div className="radio radio-primary">{this.enableOffline()}</div>

                        <div className="radio radio-primary">{this.enableStripe()}</div>
                        <div className="radio radio-primary">{this.enableKoomipay()}</div>
                        <div className="radio radio-primary">{this.enablePaypal()}</div>
                        <div className="radio radio-primary">{this.enableOmise()}</div>
                        <div className="radio radio-primary">{this.enableOmisePayNow()}</div>
                        <div className="radio radio-primary">{this.enableRazerpay()}</div>
                        <div className="radio radio-primary">{this.enablePromotion()}</div>
                      </div>
                      <div className="space-10"></div>

                      <div id="koomipay_btn" style={{ display: "none", paddingBottom: 30 }}>
                        <KoomipayCheckout
                          key={totalAmount}
                          onComplete={({ paymentID }) => {
                            this.postOrder(3, "", paymentID)
                          }}
                          order={this.state.validatedOrder}
                          paymentAmount={totalAmount}
                          clientKey={this.state.settings.koomipay_client_key}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-50" style={{ padding: "22px" }}></div>

            <div className="row" style={{ display: this.checkOutFlag() }}>
              <div style={{ display: this.stripeSts() }} id="stripe_btn">
                {" "}
                {parseInt(this.state.settings.client_stripe_enable) === 1 && parseInt(this.state.settings.client_stripe_availability) === 1 && (
                  <StripeCheckout
                    name={config.stripe.stripeCompany}
                    image={config.stripe.stripeImage}
                    description={this.stripeDescription()}
                    token={this.onToken}
                    stripeKey={this.state.settings.stripe_public_key}
                    amount={this.state.newVoucher != "1" ? 100 * (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2) : 100 * parseFloat(this.state.cartDetails.cart_sub_total).toFixed(2)}
                    email={cookie.load("UserEmail")}
                    currency={config.stripe.stripeCurrency}
                  >
                    {" "}
                    <button className="col-lg-12 continue-but desktop-container container-fluid">CHECKOUT</button>
                  </StripeCheckout>
                )}{" "}
              </div>

              <div id="omise_btn" style={{ display: this.omiseSts() }}>
                <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.payNow.bind(this, "OMISE")}>
                  CHECKOUT
                </button>
              </div>
              <div id="omise_btn_paynow" style={{ display: this.omiseSts() }}>
                <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.payNow.bind(this, "OMISE-PAYNOW")}>
                  CHECKOUT
                </button>
              </div>
              <div style={{ display: this.razerpaySts() }} id="razerpay_btn">
                <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.payNow.bind(this)}>
                  CHECKOUT
                </button>{" "}
              </div>
              <div style={{ display: this.cashSts() }} id="cash_btn">
                <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.payNow.bind(this)}>
                  CHECKOUT
                </button>{" "}
              </div>

              <div style={{ display: this.offlineSts() }} id="offline_btn">
                <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.payNow.bind(this, "OFFLINE")}>
                  CHECKOUT
                </button>{" "}
              </div>

              {/* Temporary disable Paypal */}
              {/* <div id="paypal_btn" className="col-lg-12 continue-but desktop-container container-fluid" style={{ textAlign: "center", display: this.paypalSts() }}>
                <PaypalExpressBtn style={{ tagline: false, color: "white" }} env={envSts} client={client} currency="SGD" total={totalAmount} onError={this.onError} onSuccess={this.onSuccess} onCancel={this.onCancel} />
              </div> */}

              <div style={{ display: this.promoSts() }} id="promo_btn">
                <button className="col-lg-12 continue-but desktop-container container-fluid" onClick={this.payNow.bind(this, "PROMOTION")}>
                  CHECKOUT
                </button>{" "}
              </div>
            </div>

            {/* popup */}
            <div className="modal" id="SwitchingError">
              <div className={this.state.defaultClass}></div>
              <div className="chk_popup" id="PayProcess">
                <div className="white-outer center-block process_order">
                  <div className="row ">
                    <div className="col-xs-12 padd2">
                      <h4>We are processing your order</h4>
                      <div className="clock_part">
                        <img src="/img/spinner.gif" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white-outer center-block pay_process">
                  <div className="row ">
                    <div className="col-xs-12">
                      <ul>
                        {this.onlinePaymentLoading()}
                        <li>
                          <div className="img">
                            <img src={this.state.orderLoading} alt="" />
                          </div>
                          <div className="cont">
                            <p className="date">
                              {currentDate} <span className="time">{currentTime}</span>
                            </p>
                            <p>We are placing your order details.</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/**/}

            {/* popup */}
            <div className="modal" id="advocadopopup">
              <div className="signup_popup">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="white-outer">
                  <div className=" signup_poptt">
                    <h3>Sign up For Rewards</h3>
                  </div>
                  <div className="signup_popform">
                    <div className="form-group">
                      <input type="text" readOnly value={cookie.load("UserMobile")} placeholder={"test"} className="form-control" />
                    </div>
                    <div className="form-group">
                      <input type="text" value={this.state.advocadopin} onChange={this.advocadopin.bind(this)} placeholder={"Create Your 4 Digit Pin"} className="form-control" />
                      <p>Pin required to redeem points in outlets & online orders</p>
                    </div>
                    <div className="form-group">
                      <span>
                        {" "}
                        <input type="checkbox" value="Yes" checked="checked" /> I Agree to the{" "}
                        <a onClick={this.showPDPA.bind(this)} className="pdpaopen">
                          PDPA
                        </a>
                      </span>
                    </div>
                    <div className="signuppop_btnsec">
                      <button onClick={this.advocadoregister.bind(this)} className="btn bg-black">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
                <div className="pdpa">
                  <a onClick={this.showPDPA.bind(this)} className="pdpaopen">
                    <button type="button" className="close" data-dismiss="pdpa" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </a>
                  <h4>{this.state.settings.company_name} PDPA</h4>
                  {htmlToReactParserTo.parse(this.state.pdpacontent)}
                </div>
              </div>
            </div>

            <div className="modal" id="offlinePaymentInfo">
              <div className="signup_popup">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="white-outer">
                  <div className=" signup_poptt">
                    <h3>Payment Information</h3>
                  </div>
                  <div className="signup_popform">{htmlToReactParserTo.parse(this.state.offlineDesc)}</div>
                </div>
              </div>
            </div>

            {/* Omise Popup Start */}
            <div className="modal loading-popup" id="loading-popup"></div>
            <div className="white-outer loading-popup-img">
              <p>
                <img src="/img/loader.gif" alt="Loading..." />
              </p>
            </div>
            <div className="modal omise_popsec" id="omise-paynow-popup">
              <div className="white-outer">
                <div className="omise_pophead">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="omisepop_logo">
                    <img src="/img/omise-icon.png" className="img-responsive img-width-120" alt="omise" />
                  </div>
                  <h3>
                    <img src="/img/PayNow.png" className="img-responsive width-150" alt="omise" />
                  </h3>
                </div>
                <div className="omisepop_in">
                  <p className="omise-pay-now-notes">Scan the QR code to pay.</p>
                  <img src="" id="paynow-qr-image" />
                  <p className="omise-paynow-amount-to-paid">
                    <span></span>
                  </p>
                  <p className="paynow-seconds">
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                    <span></span>
                  </p>

                  <p className="alert-payment">Your payment is being submitted. Please do not close this window or click the Back button on your browser.</p>
                  <div className="device-notes">
                    <div className="device-mobile">
                      <h2>Instructions:</h2>
                      <p>If you are on mobile, save the image above to your photo library or take a screenshot of this page. Then proceed to your mobile banking application's Scan & Pay page and upload the image.</p>
                    </div>
                    <div className="device-desktop">
                      <h2>Instructions:</h2>
                      <ul>
                        <li>Take a screenshot of the QR code and pay using your Bank app</li>
                        <li>You will be paying to Omise Payment SG (Authorised payment processor) for {this.state.settings.company_name}.</li>
                        <li>Once you make the payment, payment status of the order will reflect as 'paid', please do not make multiple payments with the same QR Code.</li>
                        <li>Page will automatically refresh after your successful payment.</li>
                        {APPID === "76DABFCC-0A47-40C7-8588-E3C32F87B3C8" && <li>Please Contact us at 90081545 if you are having trouble with payment.</li>}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal omise_popsec" id="omisepopup">
              <div className="white-outer">
                <div className="omise_pophead">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <div className="omisepop_logo">
                    <img src={config.stripe.stripeImage} className="img-responsive img-width-120" alt="omise" />
                  </div>
                  <h3>Payment</h3>
                </div>
                <div className="omisepop_in">
                  <div className="form_sec">
                    <div className="">
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label" htmlFor="holdername">
                            Card Holder Name
                          </label>
                          <input type="text" id="holdername" className="form-control" value={this.state.holdername} onChange={this.handleChangeTxt.bind(this, "holdername")} onKeyPress={(e) => helper.alphabet(e)} />
                          {this.state.omisenameerror}
                        </div>
                      </div>
                      <div className="form-group label-floating is-empty card-no">
                        <div className="input-group">
                          <label className="control-label" htmlFor="omise_card_number">
                            Card Number
                          </label>
                          <input type="number" className="form-control" value={this.state.cardNumber} id="omise_card_number" onChange={this.handleChangeTxt.bind(this, "cardNumber")} onKeyPress={(e) => helper.numariconly(e)} />
                          {this.state.cardImage}
                          {this.state.omisecardrror}
                        </div>
                      </div>
                      <div className="form-group form_expire_row">
                        <label className="black">Expiration Date</label>
                        <div className="row">
                          <div className="col-xs-6">
                            <div className="form-group label-floating is-empty card-month card-no">
                              <div className="input-group">
                                <label className="control-label" htmlFor="expiration_month">
                                  MM
                                </label>
                                <input type="number" className="form-control" maxLength="2" id="expiration_month" value={this.state.expiration_month} onChange={this.handleChangeTxt.bind(this, "expiration_month")} onKeyPress={(e) => helper.numariconly(e)} />
                                {this.state.omisemontherror}
                              </div>
                            </div>
                          </div>
                          <div className=" col-xs-6 ">
                            <div className="form-group label-floating is-empty card-year card-no">
                              <div className="input-group">
                                <label className="control-label" htmlFor="expiration_year">
                                  YY
                                </label>
                                <input type="number" className="form-control" maxLength="4" id="expiration_year" value={this.state.expiration_year} onChange={this.handleChangeTxt.bind(this, "expiration_year")} onKeyPress={(e) => helper.numariconly(e)} />
                                {this.state.omiseyearerror}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group label-floating is-empty card-no">
                      <div className="input-group">
                        <label className="control-label" htmlFor="security_code">
                          CVV
                        </label>
                        <input type="number" maxLength="4" className="form-control" id="security_code" value={this.state.security_code} onChange={this.handleChangeTxt.bind(this, "security_code")} onKeyPress={(e) => helper.numariconly(e)} />
                        {this.state.omisecodeerror}
                      </div>
                    </div>
                    <div className="btn_sec">
                      <button onClick={this.getOmiseToken.bind(this)} className="btn bg-black">
                        Pay {config.stripe.stripeCurrency} {config.ids.currency}{" "}
                        {this.getApplyRewardsPaymentTotal(this.state.newVoucher != "1" ? (parseFloat(this.state.cartDetails.cart_grand_total) + parseFloat(this.state.cartDetails.service_charge_amount)).toFixed(2) : this.state.cartDetails.cart_sub_total)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Omise Popup End */}
          </div>
        </div>
      )
    }
  }
}
export default Chekout
